<template>
  <div id="app">
    <navModule v-if="$route.path == '/login' ? false : true"></navModule>
    <div id="box-top" v-if="$route.path == '/login' ? false : true"></div>
    <mNavModule class="m_nav"></mNavModule>
    <router-view />
    <footersModule></footersModule>
  </div>
</template>
<script type="text/ecmascript-6">
import footersModule from "@/components/footers_module.vue"; //页脚
import navModule from "@/components/nav_module.vue"; //导航栏
import mNavModule from "@/components/m_nav_module.vue"; //移动端导航栏

export default {
  name: "",
  data() {
    return {};
  },
  components: {
    footersModule,
    navModule,
    mNavModule
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #212121;
  #box-top {
    height: 116px;
  }
  .page {
    min-height: 66vh;
  }
  .m_nav{
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    background-color: red;
    z-index: 9999;
    display: none;
  }
}
@media (max-width: 750px) {
  #app {
    #box-top {
      height: 106px;
    }
  }
  .m_nav{
    display: block!important;
  }
}
</style>
