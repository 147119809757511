import axios from 'axios';
import { getStore, setStore } from './storage';
import router from '../router/index';
import { Message } from 'view-design';
// import Cookies from 'js-cookie';
import Qs from 'qs'
// 统一请求路径前缀
var base = process.env.VUE_APP_BASE_API;
console.log(process.env.VUE_APP_BASE_API)
console.log(process.env.NODE_ENV)


// 超时设定
axios.defaults.timeout = 15000;
// axios.defaults.baseurl = 'http://192.168.1.178:8888/xboot'

// http request 请求拦截器
axios.interceptors.request.use(config => {
    // console.log(config)//post请求时才有数据
    return config;
}, err => {
    console.log(error)
    Message.error('请求超时');
    return Promise.resolve(err);
});
// http response 响应拦截器
axios.interceptors.response.use(response => {
    // console.log(response)
    const data = response.data;
    // console.log(data)
    // 根据返回的code值来做不同的处理(和后端约定)
    switch (data.code) {
        case 401:
            // 未登录 清除已登录状态
            // Cookies.set('userInfo', '');
            setStore('accessToken', '');
            sessionStorage.clear();
            if (router.history.current.name != "login") {
                if (data.message !== null) {
                    Message.error(data.message);
                } else {
                    Message.error("未知错误，请重新登录");
                }
                router.push({ path: '/login' });
            }
            break;
        case 403:
            // 没有权限
            if (data.message !== null) {
                Message.error(data.message);
            } else {
                Message.error("未知错误");
            }
            break;
        case 500:
            // 错误
            if (data.message !== null) {
                Message.error(data.message);
            } else {
                Message.error("未知错误");
            }
            break;
            case 503:
                // 未登录 清除已登录状态
                // Cookies.set('userInfo', '');
                setStore('accessToken', '');
                sessionStorage.clear();
                if (router.history.current.name != "login") {
                    if (data.message !== null) {
                        Message.error(data.message);
                    } else {
                        Message.error("未知错误，请重新登录");
                    }
                    router.push({ path: '/login' });
                }
                break;
        default:
            return data;
    }
    return data;
}, (err) => {
    // 返回状态码不为200时候的错误处理
    Message.error(err.toString());
    return Promise.resolve(err);
});

export const getRequest = (url, params) => {
    let accessToken = getStore('accessToken');
    // let accessToken = getStore('user') ? JSON.parse(getStore('user')).accessToken : ''
    return axios({
        method: 'get',
        url: `${base}${url}`,
        params: params,
        headers: {
            'accessToken': accessToken
        }
    });
};

export const postRequest = (url, params) => {
    let accessToken = getStore("accessToken");
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params,
        transformRequest: [function (data) {
            let ret = '';
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
            }
            ret = ret.substring(0, ret.length - 1);
            return ret;
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'accessToken': accessToken
        }
    });
};

export const putRequest = (url, params) => {
    let accessToken = getStore("accessToken");
    return axios({
        method: 'put',
        url: `${base}${url}`,
        data: params,
        transformRequest: [function (data) {
            let ret = '';
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
            }
            ret = ret.substring(0, ret.length - 1);
            return ret;
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'accessToken': accessToken
        }
    });
};

export const deleteRequest = (url, params) => {
    let accessToken = getStore('accessToken');
    return axios({
        method: 'delete',
        url: `${base}${url}`,
        params: params,
        headers: {
            'accessToken': accessToken
        }
    });
};

export const importRequest = (url, params) => {
    let accessToken = getStore('accessToken');
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params,
        headers: {
            'accessToken': accessToken
        }
    });
};

export const uploadFileRequest = (url, params) => {
    let accessToken = getStore('accessToken');
    return axios({
        method: 'post',
        url: `${base}${url}`,
        params: params,
        headers: {
            'accessToken': accessToken
        }
    });
};

/**
 * 无需token验证的请求 避免旧token过期导致请求失败
 * @param {*} url 
 * @param {*} params 
 */
export const getRequestWithNoToken = (url, params) => {
    return axios({
        method: 'get',
        url: `${base}${url}`,
        // 重点在这里
        paramsSerializer: params => {
            return Qs.stringify(params, { indices: false })
        },
        params: params
    });
};
export const postRequestWithNoToken = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params,
        transformRequest: [function (data) {
            let ret = '';
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
            }
            ret = ret.substring(0, ret.length - 1);
            return ret;
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};
export const putRequestWithNoToken = (url, params) => {
    return axios({
        method: 'put',
        url: `${base}${url}`,
        data: params,
        transformRequest: [function (data) {
            let ret = '';
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
            }
            ret = ret.substring(0, ret.length - 1);
            return ret;
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};