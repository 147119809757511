<template>
  <div class="page">
    <section
      class="display-area-banner relative"
      :style="{ backgroundImage: `url(${banner})` }"
    >
      <div class="container">
        <div class="row banner" style="height: 160px">
          <div class="banner-content col-lg-12 col-md-12">
            <h1 class="banner_title">展区示意图</h1>
            <h5 class="banner_desc">
              最终规划以组委会公布为准，此图仅做参考使用。
            </h5>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <img :src="static1" alt="" style="width: 100%" />
      </div>
    </section>
    <section class="list_section section1 relative">
      <div class="container">
        <div class="list">
          <ul class="row">
            <li
              class="col-lg-3 col-12"
              v-for="(item, index) in data"
              :key="index"
              v-if="index < 4"
            >
              <div class="list_con">
                <p class="title">
                  {{ item.title }}
                </p>
                <p class="desc">{{ item.desc }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section style="background-color: #f9f9f9; padding: 30px 0">
      <div class="container">
        <img :src="static2" alt="" style="width: 100%" />
      </div>
    </section>
    <section class="list_section section2 relative">
      <div class="container">
        <div class="list">
          <ul class="row">
            <li
              class="col-lg-3 col-12"
              v-for="(item, index) in data"
              :key="index"
              v-if="index > 3 && index < 8"
            >
              <div class="list_con">
                <p class="title">
                  {{ item.title }}
                </p>
                <p class="desc">{{ item.desc }}</p>
              </div>
            </li>
            <li
              class="col-12"
              v-for="(item, index) in data"
              :key="index"
              v-if="index > 7"
            >
              <div class="list_con">
                <p class="title">
                  {{ item.title }}
                </p>
                <p class="desc">{{ item.desc }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banner: require("@/assets/img/boothLayout/banner.jpg"),
      static1: require("@/assets/img/boothLayout/static1.png"),
      static2: require("@/assets/img/boothLayout/static2.png"),
      activeIndex: 0,
      data: [
        {
          title: "河南省特色食品展区",
          desc: "主要展销河南省辖市具有代表性的特色食品、农产品、餐饮食材等。",
        },
        {
          title: "肉制品及冻品展区",
          desc:
            "香肠、火腿制品、冷冻冰鲜肉、分割肉及肉制调理食材、冷冻/速冻、水产调理食材及深加工制品等。",
        },
        {
          title: "境外食品展区",
          desc:
            "境外酒类产品、咖啡类产品、休闲食品、保健品、橄榄油、食品添加剂等。",
        },
        {
          title: "粮油调味品展区",
          desc:
            "大米、小米、面粉、五谷杂粮、食用油、各种酱油、鸡精等调味类产品。",
        },
        {
          title: "休闲食品展区",
          desc:
            "膨化品、烘焙品、见过、甜食、糖果、巧克力、饼干、蜜饯、谷物、果脯类、燕窝、营养食品系列等。",
        },
        {
          title: "酒水饮料展区",
          desc:
            "国产白酒、葡萄酒、啤酒、功能酒、进口酒、碳酸饮料、水、茶饮、果汁、常温奶品、功能饮料、咖啡饮料、乳制品等。",
        },
        {
          title: "烘焙食品展区",
          desc: "烘焙食品、烘焙原料、蛋糕奶油、烘焙辅料、特许加盟等。",
        },
        {
          title: "食品机械展区",
          desc:
            "食品加工机械、包装机械、包装材料及制品、辅助生产社保、冷冻、冷藏及保险设备、纸箱机械、塑料机械等。",
        },
        {
          title: "综合展区",
          desc: "漯河市各县区特色食品、蔬果、特色农产品、有机食品、乳制品等。",
        },
      ],
      productType: "综合展区",
      form: {
        size: 16,
        current: 1,
      },
    };
  },
  methods: {},
  created() {},
};
</script>

<style scoped lang="less">
.display-area-banner {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  padding: 20px 0;
  div.banner {
    .banner-content {
      text-align: left;
      margin: 0;
    }
    .banner_title {
      //   font-size: 37px;
      color: #ffffff;
      margin-top: 25px;
    }
    .banner_desc {
      //   font-size: 16px;
      color: #ffffff;
    }
  }
}
.section1.list_section {
  margin-bottom: 30px;
  .list ul {
    li {
      &:nth-child(1) {
        div.list_con {
          background: #a7d5ed;
        }
      }
      &:nth-child(2) {
        div.list_con {
          background: #dab9ce;
        }
      }
      &:nth-child(3) {
        div.list_con {
          background: #bcddcc;
        }
      }
      &:nth-child(4) {
        div.list_con {
          background: #eeefb6;
        }
      }
    }
  }
}
.section2.list_section {
  margin-bottom: 30px;
  .list ul {
    li {
      &:nth-child(1) {
        div.list_con {
          background: #97c782;
        }
      }
      &:nth-child(2) {
        div.list_con {
          background: #f8f184;
        }
      }
      &:nth-child(3) {
        div.list_con {
          background: #bcddcc;
        }
      }
      &:nth-child(4) {
        div.list_con {
          background: #766eaa;
        }
      }
      &:nth-child(5) {
        padding: 10px 2px;
        div.list_con {
          background: #ea8764;
          min-height: 30px;
          .title {
            width: 65px;
            display: inline-block;
            vertical-align: middle;
            padding: 10px;
            line-height: 22px;
          }
          .desc {
            width: calc(100% - 70px);
            display: inline-block;
            vertical-align: middle;
            min-height: 30px;
          }
        }
      }
    }
  }
}
.list_section {
  margin-top: 40px;
  h5 {
    margin: 30px 0;
  }
  .list {
    ul {
      li {
        margin: 10px 0;
        &.col-lg-3 {
          padding: 0 4px;
        }
        .list_con {
          border: 1px solid #eeeeee;
          // min-height: 230px;
          padding: 10px 0px;
          background-color: #f9f9f9;
          &:hover {
            box-shadow: 0px 0px 6px 0px rgba(39, 39, 39, 0.16);
            i {
              color: #3f6ce2;
            }
          }
        }
        img {
          width: 100%;
          padding: 10px;
        }
        p {
          font-size: 14px;
          color: #212121;
          text-align: left;
          line-height: 30px;
        }
        p.desc {
          background: #fff;
          min-height: 120px;
          padding: 10px;
          margin: 10px 0;
        }
        p.title {
          font-size: 16px;
          color: #212121;
          text-align: center;
          margin: 12px 0;
        }
      }
    }
  }
  .no_more {
    height: 40vh;
    padding-top: 100px;
    i {
      font-size: 100px;
    }
    p {
      margin: 20px;
    }
  }
  .more {
    margin: 10px 0;
    div {
      display: inline-block;
      padding: 6px 30px;
      border: 1px solid #ff6600;
      border-radius: 8px;
      cursor: pointer;
      i {
        color: #ff6600;
      }
      span {
        font-size: 16px;
        color: #ff6600;
      }
    }
  }
}
@media (max-width: 750px) {
  .list_con {
    // min-height: 330px !important;
  }
  .section1.list_section {
    margin: 0;
    .list {
      ul {
        li {
          margin: 0;
        }
      }
    }
  }
  .section2.list_section {
    margin-top: 0;
    .list {
      ul {
        li {
          margin: 0;
          &:nth-child(5) {
            padding: 0 2px;
          }
        }
      }
    }
  }
}
</style>