<template>
  <div class="page">
    <div class="container">
      <div class="page_con row">
        <div class="tab_left col-lg-4 col-2">
          <p class="name">{{ data.departmentName }}</p>
          <div
            class="tab"
            :class="activeIndex == 0 ? 'active' : ''"
            @click="chengeTab(0)"
          >
            <i class="iconfont iconxinxi"> </i>
            <span> 基本信息 </span>
          </div>
          <div
            class="tab"
            :class="activeIndex == 1 ? 'active' : ''"
            @click="chengeTab(1)"
          >
            <i class="iconfont icon3Dzhanshi"> </i>
            <span> 我的票证 </span>
          </div>
          <div
            class="tab"
            :class="activeIndex == 2 ? 'active' : ''"
            @click="chengeTab(2)"
          >
            <i class="iconfont iconshangpin"> </i>
            <span> 展馆位置 </span>
          </div>
        </div>
        <div class="tab_right col-lg-8 col-10">
          <p class="name">{{ data.departmentName }}</p>
          <section class="section1" v-show="activeIndex == 0">
            <div class="title">
              <i></i>
              <span>基本信息</span>
            </div>
            <p>姓名：{{ data.nickName }}</p>
            <p>手机号：{{ data.mobile }}</p>
            <p v-if="data.jobName">职位：{{ data.jobName }}</p>
            <p v-if="data.address">省/市/区：{{ data.address }}</p>
            <p v-if="data.email">邮箱：{{ data.email }}</p>
            <p v-for="(item, index) in data.questionnaireVOS" :key="index">
              <span>{{ item.title }}：</span>
              <span v-for="(it, i) in item.options" :key="i"
                >{{ i + 1 }}、{{ it }}</span
              >
            </p>
            <!-- <p>企业所属行业：{{data.nickName}}</p> -->
          </section>
          <section class="section2" v-show="activeIndex == 1">
            <div class="title">
              <i></i>
              <span>我的票证</span>
              <em>请妥善保存票证图片，此为入场凭证</em>
            </div>
            <div class="code-body">
              <img :src="logo" alt="" />
              <h5 class="modal-title" id="staticBackdropLabel">
                2022第二十届中国（漯河）食品博览会
              </h5>
              <div class="box">
                <div
                  class="code"
                  :style="{ backgroundImage: `url(${code_back})` }"
                >
                  <h4>电子入场券</h4>
                  <img :src="code" alt="" />
                </div>
              </div>
            </div>
          </section>
          <section class="section1" v-show="activeIndex == 2">
            <div class="title">
              <i></i>
              <span>展馆位置</span>
            </div>
            <div class="map">
              <div id="GDMap"></div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AMap from "AMap"; //在页面中引入高德地图
import { getPersonalInfo } from "@/api/index.js";
export default {
  data() {
    return {
      activeIndex: 0,
      logo: require("@/assets/img/home/logo.png"),
      code: require("@/assets/img/signUp/code.jpg"),
      code_back: require("@/assets/img/signUp/code_back.jpg"),
      data: {},
    };
  },
  methods: {
    getData() {
      getPersonalInfo().then((res) => {
        if (res.success) {
          this.data = res.result;
        }
      });
    },
    chengeTab(v) {
      this.activeIndex = v;
    },
    loadmap() {
      let marker,
        map = new AMap.Map("GDMap", {
          resizeEnable: true,
          zoom: 15,
          center: [114.021707, 33.605934],
        });
      marker = new AMap.Marker({
        icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
        position: [114.021707, 33.605934],
      });
      marker.setLabel({
        //label默认蓝框白底左上角显示，样式className为：amap-marker-label
        offset: new AMap.Pixel(-70, -30), //修改label相对于maker的位置
        content: "漯河国际会展中心",
      });
      marker.setTitle("地址：漯河市郾城区嵩山东支路");
      marker.setMap(map);
    },
  },
  mounted() {
    this.loadmap();
    this.getData();
  },
};
</script>

<style scoped lang="less">
@media (max-width: 750px) {
  .code-body {
    width: 100% !important;
  }
}
.page {
  background-color: #f9f9f9;
  padding: 20px 0;
  .map {
    width: 97%;
    height: 400px;
    padding-top: 15px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px;
    overflow: hidden;
    background: #f0f0f0;
    margin-top: 20px;
    text-align: center;
    #GDMap {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      margin: 10px;
      width: 90%;
      display: inline-block;
      height: 350px;
      border: 4px solid #fff;
    }
  }
  .page_con {
    background-color: #fff;
    padding: 30px 20px;
    min-height: 70vh;
    .tab_left {
      background-color: #fafafa;
      padding: 10px 0;
      p.name {
        font-size: 14px;
        color: #595959;
        padding: 10px 0;
        border-bottom: 1px solid #ddd;
      }
      div.tab {
        padding: 10px 0;
        text-align: center;
        cursor: pointer;
        &:hover,
        &.active {
          background-color: #eeeeee;
        }
        &:nth-child(2) {
          i {
            color: #5883f0;
          }
        }
        &:nth-child(3) {
          i {
            color: #ff8b29;
          }
        }
        &:nth-child(4) {
          i {
            color: #2bc177;
          }
        }
        i {
          font-size: 20px;
          vertical-align: middle;
        }
        span {
          font-size: 16px;
          color: #595959;
          vertical-align: middle;
        }
      }
    }
    .tab_right {
      padding: 0 20px;
      text-align: left;
      p.name {
        display: none;
        font-size: 14px;
        color: #595959;
        padding: 10px 0;
        border-bottom: 1px solid #ddd;
      }
      .title {
        i {
          display: inline-block;
          border: 2px solid #ff8b29;
          height: 18px;
          vertical-align: middle;
          margin-right: 6px;
        }
        span {
          font-size: 16px;
          color: #595959;
          vertical-align: middle;
        }
        em {
          font-size: 14px;
          color: #999999;
          font-style: normal;
          margin-left: 10px;
        }
      }
      p {
        font-size: 14px;
        color: #313131;
        padding: 10px;
      }
    }
    .code-body {
      background: #ffffff;
      box-shadow: 0px 0px 5px 0px rgba(39, 39, 39, 0.16);
      border-radius: 4px;
      margin-top: 20px;
      padding: 10px;
      text-align: center;
      width: 60%;
      .modal-title {
        font-size: 12px;
        font-weight: 400;
        color: #000000;
      }
      .box {
        padding: 20px 10px;
        h4 {
          color: #fff;
          padding: 0px 0 20px 0;
        }
        .code {
          background-size: cover;
          padding: 20px 0px;
          img {
            width: 100%;
            padding: 0 20px;
          }
          p {
            color: #fff;
            font-size: 14px;
            margin: 10px 0 0 0;
          }
        }
      }
    }
    .section1 {
      p {
        margin-bottom: 0px;
        padding: 6px 10px;
      }
    }
  }
}
@media (max-width: 750px) {
  .page .page_con .tab_left div.tab span {
    display: none;
  }
  .page .page_con .tab_right {
    padding-right: 0px;
  }

  .page .page_con .tab_left p.name {
    display: none;
  }
  .page .page_con .tab_right p.name {
    display: block;
  }
}
</style>