<template>
  <div class="page">
    <div class="container">
      <div class="page_con">
        <div class="page_title">中国（漯河）食品博览会 - 历届回顾</div>
        <div class="page_item_con">
          <ul>
            <li
              v-for="(item, index) in data"
              :key="index"
              @click="openWeb('/infoDetails?id=' + item.id)"
            >
              <div class="list_con row">
                <div class="left_con col-lg-3 col-6" v-if="item.image">
                  <img :src="item.image" alt="" />
                </div>
                <div
                  class="right_con col-lg-9 col-12"
                  :class="item.image ? 'col-lg-9' : 'col-lg-12'"
                >
                  <div class="title">
                    <p>
                      {{ item.title }}
                    </p>
                    <p v-if="item.createTime">
                      {{ item.createTime.slice(0, 10) }}
                    </p>
                  </div>
                  <p class="desc">
                    {{item.abstractsValue}}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInfoData } from "@/api/index.js";
export default {
  data() {
    return {
      data: [],
    };
  },
  methods: {
    openWeb(v) {
      this.$router.push(v);
    },
    getData() {
      getInfoData({ type: "历届展会", size: 100 }).then((r) => {
        if (r.success) {
          this.data = r.result.records;
        } else {
          this.data = [];
        }
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped lang="less">
.page {
  background: #f9f9f9;
  padding: 30px 0;
  .page_con {
    background: #fff;
    padding: 30px 20px;
    .page_title {
      text-align: left;
      font-size: 18px;
      color: #595959;
      padding: 20px;
      border-bottom: 1px solid #eee;
    }
    .page_item_con {
      padding: 30px 10px;
      font-size: 14px;
      color: #595959;
      text-align: left;
      line-height: 28px;
      ul {
        li {
          margin-top: 20px;
          cursor: pointer;
          .list_con {
            .left_con {
              width: 100%;
              img {
                width: 100%;
              }
            }
            .right_con {
              padding: 0 40px;
              .title {
                p {
                  display: inline-block;
                  vertical-align: middle;
                  &:nth-child(1) {
                    width: calc(100% - 100px);
                    font-size: 16px;
                    color: #595959;
                  }
                  &:nth-child(2) {
                    font-size: 14px;
                    color: #999999;
                  }
                }
              }
              p.desc {
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                font-size: 14px;
                color: #999999;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 750px) {
  .left_con {
    display: none;
  }
  .right_con {
    padding: 0 10px !important;
  }
  .title {
    p {
      &:nth-child(1) {
        width: 100% !important;
      }
      &:nth-child(2) {
        display: none !important;
      }
    }
  }
}
</style>