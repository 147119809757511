<template>
  <div class="page">
    <div class="container">
      <div class="page_con">
        <div class="page_title">
          <p
            v-for="(item, index) in tabList"
            :key="index"
            :class="activeIndex == index ? 'active' : ''"
            @click="changeTab(index, item.name)"
          >
            {{ item.name }}
          </p>
        </div>
        <div class="page_item_con">
          <img class="loading" :src="loading" alt="" v-if="loadingFlag" />
          <ul>
            <li
              v-for="(item, index) in data"
              :key="index"
              @click="openWeb('/infoDetails?id=' + item.id)"
            >
              <div class="list_con row">
                <div class="left_con col-lg-3 col-6" v-if="item.image">
                  <img :src="item.image" alt="" />
                </div>
                <div
                  class="right_con col-lg-9 col-12"
                  :class="item.image ? 'col-lg-9' : 'col-lg-12'"
                >
                  <div class="title">
                    <p>
                      {{ item.title }}
                    </p>
                    <p v-if="item.createTime">
                      {{ item.createTime.slice(0, 10) }}
                    </p>
                  </div>
                  <p class="desc" v-if="item.abstractsValue">
                    {{ item.abstractsValue }}
                  </p>
                </div>
              </div>
            </li>
          </ul>

          <div class="page-bar">
            <ul>
              <li v-if="cur > 1">
                <a v-on:click="cur--, pageClick()">上一页</a>
              </li>
              <li v-if="cur == 1"><a class="banclick">上一页</a></li>
              <li
                v-for="index in indexs"
                :key="index"
                v-bind:class="{ active: cur == index }"
              >
                <a v-on:click="btnClick(index)">{{ index }}</a>
              </li>
              <li v-if="cur != all">
                <a v-on:click="cur++, pageClick()">下一页</a>
              </li>
              <li v-if="cur == all"><a class="banclick">下一页</a></li>
              <li>
                <a
                  >共<i>{{ all }}</i
                  >页</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePagination from "@/components/vuePage";
import { getInfoData } from "@/api/index.js";
export default {
  data() {
    return {
      all: 10, //总页数
      cur: 1, //当前页码
      totalPage: 0, //当前条数
      pagination: {
        pageSize: 10, // 显示的条数
        totals: 0, // 总数
        currentPage: 1, // 当前第几页
      },
      activeIndex: 0,
      data: [],
      tabList: [
        {
          name: "展会新闻",
        },
        {
          name: "媒体报道",
        },
      ],
      form: {
        type: "展会新闻",
        size: 10,
      },
      loading: require("@/assets/img/loading.gif"),
      loadingFlag: false,
    };
  },
  components: {
    VuePagination,
  },
  computed: {
    //分页
    indexs() {
      var left = 1;
      var right = this.all;
      var ar = [];
      if (this.all >= 5) {
        if (this.cur > 3 && this.cur < this.all - 2) {
          left = this.cur - 2;
          right = this.cur + 2;
        } else {
          if (this.cur <= 3) {
            left = 1;
            right = 5;
          } else {
            right = this.all;
            left = this.all - 4;
          }
        }
      }
      while (left <= right) {
        ar.push(left);
        left++;
      }
      return ar;
    },
  },
  methods: {
    //分页
    btnClick(data) {
      //页码点击事件
      if (data != this.cur) {
        this.cur = data;
      }
      //根据点击页数请求数据
      //   this.dataListFn(this.cur.toString());

      this.form.current = this.cur.toString();
      this.getData();
    },
    pageClick() {
      //根据点击页数请求数据
      //   this.dataListFn(this.cur.toString());
      this.form.current = this.cur.toString();
      this.getData();
    },
    changeTab(i, v) {
      this.activeIndex = i;
      this.form.type = v;
      this.form.current = 1;
      this.getData();
    },
    openWeb(v) {
      this.$router.push(v);
    },
    getData() {
      this.loadingFlag = true;
      getInfoData(this.form).then((r) => {
        if (r.success) {
          this.data = r.result.records;
          this.all = Math.ceil(r.result.total / 10);
          this.loadingFlag = false;
        } else {
          this.data = [];
        }
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped lang="less">
.page-bar {
  margin: 40px auto;
  margin-top: 50px;
  text-align: center;
}
ul,
li {
  margin: 0px;
  padding: 0px;
}
li {
  list-style: none;
}
.page-bar li:first-child > a {
  margin-left: 0px;
}
.page-bar a {
  border: 1px solid #ddd;
  text-decoration: none;
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #5d6062;
  cursor: pointer;
  margin-right: 20px;
}
.page-bar a:hover {
  background-color: #eee;
}
.page-bar a.banclick {
  cursor: not-allowed;
}
.page-bar .active a {
  color: #fff;
  cursor: default;
  background-color: #e96463;
  border-color: #e96463;
}
.page-bar i {
  font-style: normal;
  color: #d44950;
  margin: 0px 4px;
  font-size: 12px;
}
.page {
  background: #f9f9f9;
  padding: 30px 0;
  .page_con {
    background: #fff;
    padding: 20px;
    .page_title {
      text-align: left;
      font-size: 18px;
      color: #595959;
      padding: 0 20px;
      border-bottom: 1px solid #eee;
      p {
        width: 50%;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        cursor: pointer;
        &:hover,
        &.active {
          color: #ff6900;
        }
      }
    }
    .page_item_con {
      padding: 30px 10px;
      font-size: 14px;
      color: #595959;
      text-align: left;
      line-height: 28px;
      img.loading{
        width: 100%;
      }
      ul {
        li {
          margin-top: 20px;
          cursor: pointer;
          .list_con {
            .left_con {
              width: 100%;
              img {
                width: 100%;
              }
            }
            .right_con {
              padding: 0 40px;
              .title {
                p {
                  display: inline-block;
                  vertical-align: middle;
                  &:nth-child(1) {
                    width: calc(100% - 100px);
                    font-size: 16px;
                    color: #595959;
                  }
                  &:nth-child(2) {
                    font-size: 14px;
                    color: #999999;
                  }
                }
              }
              p.desc {
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                font-size: 14px;
                color: #999999;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 750px) {
  .left_con {
    display: none;
  }
  .right_con {
    padding: 0 10px !important;
  }
  .title {
    p {
      &:nth-child(1) {
        width: 100% !important;
      }
      &:nth-child(2) {
        display: none !important;
      }
    }
  }
}
</style>