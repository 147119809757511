<template>
  <div class="page">
    <section
      class="display-area-banner relative"
      :style="{ backgroundImage: `url(${banner})` }"
    >
      <div class="container">
        <div class="row banner" style="height: 250px">
          <div class="banner-content col-lg-12 col-md-12">
            <h3 class="banner_title">广告赞助合作</h3>
            <h6 class="banner_desc">
              漯河，一座中原内陆小城，因独特的食品工业魅力而闻名天下。2003年，中原食品节的诞生让这座食品之城与世界紧密相连，演绎了一场以强大的食品工业实力推动食品经贸交流，又以富有成效的经贸活动推动食品产业大发展的生动传奇。2008年4月，昔日的中原食品节升格为中国（漯河）食品博览会，又为这座食品名城增添了一张“烫金名片”。
            </h6>
            <div class="banner_but">
              <a href="#toc0"> <div class="but">我要报名</div></a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="expo_desc">
      <div class="container">
        <div class="border_item">
          <div class="border_title">
            <span> 01 </span>
            <em> 食博概览 </em>
          </div>
          <div class="content">
            <span>
              食博会是政府主导的国际化、市场化、专业化展示平台，立足漯河，辐射全国，已成为食品行业的年度盛典；<br />
              展会规模达 <em>65000</em> 平方米； <br />
              <em>20</em> 多个国家和地区，<em>1000</em> 多家食品企业，<em
                >65000</em
              >
              余款产品参展； <br />
              逾
              <em>200000</em> 中外客商参观、采购、洽谈；<br />
              <em>100</em> 多家传统媒体、新媒体与展会结成战略联盟，<em>360°</em>
              全方位、立体化传播。
            </span>
          </div>
          <a name="toc0" class="aaa1"></a>
        </div>
      </div>
    </section>
    <section class="sign_up">
      <div class="container">
        <div class="border_item">
          <div class="border_title">
            <span> 02 </span>
            <em> 广告位报名 </em>
          </div>
          <div class="content">
            <div class="form_con">
              <!-- <div class="form_title">
                <span>企业信息</span>
                <span
                  >（
                  <i style="color: red">*</i>
                  为必填项 ）</span
                >
              </div> -->
              <!-- 表单 -->
              <form>
                <div class="form-group row">
                  <label class="col-sm-1 col-3 col-form-label">
                    单位名称
                  </label>
                  <div class="col-sm-6 col-9">
                    <input class="form-control" v-model="form.enterpriseName" />
                  </div>
                </div>
                <!-- <div class="form-group row">
                  <label class="col-sm-1 col-3 col-form-label">
                    统一社会信用代码
                  </label>
                  <div class="col-sm-6 col-9">
                    <input class="form-control" v-model="form.uscCode" />
                  </div>
                </div> -->
                <div class="form-group row">
                  <label class="col-sm-1 col-3 col-form-label">联系人 </label>
                  <div class="col-sm-6 col-9">
                    <input class="form-control" v-model="form.contacts" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-1 col-3 col-form-label">联系方式</label>
                  <div class="col-sm-6 col-9 getPhone">
                    <input class="form-control" v-model="form.mobile" />
                  </div>
                </div>
                <!-- <div class="form-group row">
                  <label class="col-sm-1 col-3 col-form-label"
                    >验证码 <i>*</i></label
                  >
                  <div class="col-sm-6 col-9 getCode">
                    <input class="form-control" v-model="form.code" required />
                    <span v-if="show" @click="getSmsCode">获取验证码</span>
                    <span v-else>{{ count }} s后重新获取</span>
                  </div>
                </div> -->
                <!-- 单选 -->
                <div class="form-group form-check">
                  <div class="row">
                    <div class="col-sm-1 col-3" style="padding: 0">
                      <label class="col-form-label">意向广告位 </label>
                    </div>
                    <div class="col-sm-10 col-9 jump">
                      <a href="#toc1" class="jump_item">查看广告位分布</a>
                      <div
                        v-for="(item, index) in selectInfo"
                        :key="index"
                        style="margin-bottom: 20px"
                      >
                        <label
                          class="form-check-label"
                          style="color: red; font-weight: 600"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="inlineRadioOptions"
                            id="inlineRadio"
                            :value="item.check"
                            @click="chengeRadio1(index, item.title)"
                          />
                          <span style="font-size: 16px">{{ item.title }}</span>
                        </label>
                        <p class="check_desc">
                          {{ item.desc }}
                        </p>
                        <!--子级的复选框 -->
                        <div
                          class="child_con"
                          v-show="item.check"
                          style="margin-top: 10px"
                        >
                          <div
                            class="child_item"
                            v-for="(it, i) in item.children"
                            :key="i"
                          >
                            <label class="form-check-label">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="inlineRadioOptions"
                                id="inlineRadio"
                                :value="it.check"
                                @click="chengeRadio2(index, i)"
                              />
                              <span>{{ it.name }}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="but">
                  <p class="error_msg">{{ Msg }}</p>
                  <span @click="submit" class="btn btn-primary"
                    >提交报名信息</span
                  >
                </div>
              </form>
              <a name="toc1" class=""></a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="adv_seat_con">
      <div class="container">
        <div class="aseat_title">
          <em></em>
          <span> 更多广告资源 </span>
          <em></em>
        </div>
        <div class="aseat_content">
          <div class="con_item">
            <div class="con_image">
              <img :src="adv" alt="广告位图加载失败" />
              <ul>
                <li
                  :class="'item' + item.id"
                  v-for="(item, index) in dotList"
                  :key="index"
                  :style="{
                    left: item.left,
                    top: item.top,
                    bottom: item.bottom,
                    right: item.right,
                  }"
                >
                  <div class="desc">
                    <em></em>
                    <span>{{ item.desc }}</span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="adv_list_item">
              <ul>
                <li v-for="(item, index) in advList" :key="index">
                  <em>{{ index + 1 }}</em>
                  <span> {{ item.name }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 表单验证失败 -->
    <!--  <div
      class="position-fixed bottom-0 right-0 p-3"
      style="z-index: 5; right: 0; bottom: 50%"
    >
      <div
        id="liveToast"
        class="toast"
        :class="clauseMsg ? 'show' : 'hide'"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-delay="2000"
        style="padding: 20px"
      >
        <div class="toast-header">
          <strong class="mr-auto">表单验证失败</strong>
          <button
            type="button"
            class="ml-2 mb-1 close"
            data-dismiss="toast"
            aria-label="Close"
            @click="clauseMsg = false"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body">{{ Msg }}</div>
      </div>
    </div> -->
    <!-- 弹框 -->
    <!--  <button
      type="button"
      class="btn btn-primary"
      data-toggle="modal"
      data-target="#staticBackdrop"
    >
      Launch static backdrop modal
    </button> -->
    <div
      class="modal"
      :class="modal ? 'active' : ' fade'"
      id="staticBackdrop"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <img :src="success" alt="" />
            <div>报名提交成功！</div>
            <p>报名提交之后请耐心等待组委会的审核。</p>
            <!-- <span @click="closeModal()">查看我的报名</span> -->
          </div>
          <!-- <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              我已知晓
            </button>
          </div> -->
        </div>
      </div>
    </div>
    <div class="modal-backdrop show" :class="modal ? 'active' : ''"></div>
  </div>
</template>



<script>
import {
  getQuestionnaireById,
  getLoginCode,
  checkMobile,
  enterpriseDataApply,
} from "@/api/index.js";
// 单页面引入VDistpicker模块
export default {
  data() {
    return {
      dotList: [
        // 从左到右
        {
          left: "",
          right: "56%",
          top: "",
          bottom: "19%",
          id: "92",
          desc: " 析架喷绘广告位",
        },
        {
          left: "",
          right: "40.5%",
          top: "",
          bottom: "18%",
          id: "91",
          desc: " 析架喷绘广告位",
        },
        {
          left: "",
          right: "48%",
          top: "",
          bottom: "24.5%",
          id: "8",
          desc: "LED屏广告",
        },
        {
          left: "",
          right: "27%",
          top: "48.5%",
          bottom: "",
          id: "62",
          desc: "展区东西门楼广告",
        },
        {
          left: "25.5%",
          right: "",
          top: "48%",
          bottom: "",
          id: "61",
          desc: "展区东西门楼广告",
        },
        {
          left: "24%",
          right: "",
          top: "63%",
          bottom: "",
          id: "75",
          desc: "展区门楼上侧广告",
        },
        {
          left: "",
          right: "29%",
          top: "47%",
          bottom: "",
          id: "74",
          desc: "展区门楼上侧广告",
        },
        {
          left: "29%",
          right: "",
          top: "47%",
          bottom: "",
          id: "73",
          desc: "展区门楼上侧广告",
        },
        {
          left: "47.5%",
          right: "",
          top: "30%",
          bottom: "",
          id: "72",
          desc: "展区门楼上侧广告",
        },
        {
          left: "48.5%",
          right: "",
          top: "54%",
          bottom: "",
          id: "71",
          desc: "展区门楼上侧广告",
        },
        {
          left: "47.5%",
          right: "",
          top: "34%",
          bottom: "",
          id: "52",
          desc: " 展区南北门楼广告",
        },
        {
          left: "48.5%",
          right: "",
          top: "58%",
          bottom: "",
          id: "51",
          desc: " 展区南北门楼广告",
        },
        {
          left: "",
          right: "37.5%",
          top: "55.5%",
          bottom: "",
          id: "32",
          desc: " 展篷顶棚广告",
        },
        {
          left: "37.5%",
          right: "",
          top: "55.5%",
          bottom: "",
          id: "31",
          desc: " 展篷顶棚广告",
        },
        {
          left: "",
          right: "48%",
          top: "",
          bottom: "14.5%",
          id: "1",
          desc: "主出入口广告",
        },
        {
          left: "",
          right: "16.5%",
          top: "",
          bottom: "13%",
          id: "42",
          desc: "广场围挡广告",
        },
        {
          left: "10.5%",
          right: "",
          top: "",
          bottom: "19.5%",
          id: "41",
          desc: "广场围挡广告",
        },
        {
          left: "",
          right: "11.5%",
          top: "44.5%",
          bottom: "",
          id: "22",
          desc: "侧出入口广告",
        },
        {
          left: "10%",
          right: "",
          top: "40%",
          bottom: "",
          id: "21",
          desc: "侧出入口广告",
        },
      ],
      advdata2: [
        {
          name: "封底广告",
          desc: "整版彩色",
        },
        {
          name: "内页广告",
          desc: "整版彩色",
        },
        {
          name: "展位图呈现公司标志",
          desc: "彩色",
        },
      ],
      advdata1: [
        {
          name: "封二广告",
          desc: "整版彩色",
        },
        {
          name: "封三广告",
          desc: "整版彩色",
        },
        {
          name: "封底广告",
          desc: "整版彩色",
        },
        {
          name: "双页跨版",
          desc: "整版彩色",
        },
        {
          name: "内页广告",
          desc: "整版彩色，整版黑白",
        },
        {
          name: "参展商名录",
          desc: "黑白",
        },
      ],
      advList: [
        {
          name: "主出入口广告",
          size: "30m×60m",
        },
        {
          name: "侧出入口广告",
          size: "18.5m×6.5m",
        },
        {
          name: "展蓬顶棚广告",
          size: "10m×5m;10m×3m",
        },
        {
          name: "广场围挡广告",
          size: "10m×2.5m",
        },
        {
          name: "展区南北门楼广告",
          size: "13.5m×5m",
        },
        {
          name: "展区东西门楼广告",
          size: "17m×7m×5m",
        },
        {
          name: "展区门楼上侧广告",
          size: "17m×7m×5m",
        },
        {
          name: "LED屏广告",
          size: "35m×6m",
        },
        {
          name: "析架喷绘广告位",
          size: "22m×6m",
        },
      ],
      areaVal: "",
      areaFlage: false,
      check_msg_phone: "手机号格式不正确",
      check_phone: false,
      count: 0,
      timer: null,
      show: true,
      modal: false, //模态框是否显示
      logo: require("@/assets/img/home/logo.png"),
      code: require("@/assets/img/signUp/code.jpg"),
      code_back: require("@/assets/img/signUp/code_back.jpg"),
      clauseMsg: false, //全局提示信息
      Msg: "",
      placeholders: {
        province: "------- 省 --------",
        city: "--- 市 ---",
        area: "--- 区 ---",
      },
      banner: require("@/assets/img/spectator/advSignUp_banner.png"),
      adv: require("@/assets/img/spectator/advImage.png"),
      success: require("@/assets/img/spectator/success.png"),
      form: {
        enterpriseName: "",
        contacts: "",
        // uscCode: "",
        mobile: "",
        adSpace: "",
      },
      selectInfo: [
        {
          title: "现场广告",
          desc: "利用展馆位置优势，向与会参展商、专业买家、社会公众及媒体展示机构/企业品牌形象，在众多参展商中脱颖而出。",
          check: false,
          children: [
            {
              name: "主出入口",
              check: false,
            },
            {
              name: "侧出入口",
              check: false,
            },
            {
              name: "展蓬顶棚",
              check: false,
            },
            {
              name: "广场围挡",
              check: false,
            },
            {
              name: "展区门头",
              check: false,
            },
            {
              name: "LED屏",
              check: false,
            },
            {
              name: "跨街门头",
              check: false,
            },
            {
              name: "注水道旗",
              check: false,
            },
            {
              name: "导视系统",
              check: false,
            },
          ],
        },
        {
          title: "资料广告",
          desc: "通过展会胸卡、门票、参观指南、会刊等印刷品广告，为机构/企业增加曝光率，长效影响与会人群。",
          check: false,
          children: [
            {
              name: "胸卡",
              check: false,
            },
            {
              name: "胸卡挂绳",
              check: false,
            },
            {
              name: "门票",
              check: false,
            },
            {
              name: "参观指南",
              check: false,
            },
            {
              name: "展会会刊",
              check: false,
            },
          ],
        },
        {
          title: "活动赞助 ",
          desc: "展会同期论坛，云集政府领导、行业组织、专家学者、企业代表及媒体等，赞助将显著提升机构/企业的行业知名度和国际影响力。",
          check: false,
          children: [
            {
              name: "论坛赞助",
              check: false,
            },
          ],
        },
        {
          title: "实物赞助",
          desc: "赞助手提袋、礼品、饮料、饮用水等实物，由食博会组委会统一向到会者派发，为机构/企业增加现场人气和公众关注度。",
          check: false,
          children: [
            {
              name: "手提袋",
              check: false,
            },
            {
              name: "展会停车证",
              check: false,
            },
            {
              name: "礼品",
              check: false,
            },
            {
              name: "饮料",
              check: false,
            },
            {
              name: "饮用水",
              check: false,
            },
          ],
        },
      ],
      info: [
        {
          title: "展区面积",
          selection: [
            {
              name: "标摊9平方",
              check: false,
            },
            {
              name: "特装",
              check: false,
              flage: true,
            },
          ],
        },
        {
          title: "选择展区（单选）",
          selection: [
            {
              name: "河南省特色食品",
              check: false,
            },
            {
              name: "境外食品",
              check: false,
            },
            {
              name: "肉制品及冻品",
              check: false,
            },
            {
              name: "粮油及调味品",
              check: false,
            },
            {
              name: "休闲食品",
              check: false,
            },
            {
              name: "酒水饮料",
              check: false,
            },
            {
              name: "烘焙食品",
              check: false,
            },
            {
              name: "食品加工机械",
              check: false,
            },
            {
              name: "综合展区",
              check: false,
            },
          ],
        },
      ],
      baseUrl: process.env.VUE_APP_BASE_API,
      modalType: 0,
    };
  },
  methods: {
    // 关闭弹框
    closeModal() {
      this.modal = false;
      this.$router.push("/index");
    },
    blurPhone() {
      var reg = /^[1][3,4,5,6,7,8][0-9]{9}$/;
      if (!this.form.mobile) {
        // 不能为空
        this.check_phone = true;
        this.check_msg_phone = "手机号不能为空";
      } else {
        if (!reg.test(this.form.mobile)) {
          // 格式错误
          this.check_phone = true;
          this.check_msg_phone = "手机号格式不正确";
        } else {
          // 后端查重
          this.check_phone = false;
          checkMobile({ mobile: this.form.mobile }).then((r) => {
            if (r.success) {
            } else {
              this.check_phone = true;
              this.check_msg_phone = r.message;
            }
          });
        }
      }
    },
    // 获取验证码
    getSmsCode() {
      if (this.form.mobile == "") {
        this.clauseMsg = true;
        this.Msg = "手机号不能为空！";
        setTimeout(() => {
          this.clauseMsg = false;
        }, 3000);
      } else {
        if (this.count === 0) {
          getLoginCode(this.form.mobile)
            .then((r) => {
              if (r.success) {
                this.getCode();
              } else {
                this.clauseMsg = true;
                this.Msg = r.message;
                setTimeout(() => {
                  this.clauseMsg = false;
                }, 3000);
              }
            })
            .catch((e) => {
              this.clauseMsg = true;
              this.Msg = e.message;
              setTimeout(() => {
                this.clauseMsg = false;
              }, 3000);
            });
        }
      }
    },
    // 倒计时
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.count = 0;
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 触发选择省份事件
    onChangeProvince(data) {
      console.log(data);
      // this.select.area = data.value
    },
    // 触发选择城市事件
    onChangeCity(data) {
      console.log(data);
      // this.select.area = data.value
    },
    // 触发选择地区事件
    onSelectArea(data) {
      console.log(data);
      // this.select.area = data.value
    },
    // 选择最后一项时触发事件
    onSelected(data) {
      this.form.address =
        data.province.value + "/" + data.city.value + "/" + data.area.value;
    },
    // 报名
    submit() {
      this.form.adSpace = JSON.stringify(this.selectInfo);
      enterpriseDataApply(this.form).then((res) => {
        if (res.success) {
          this.modal = true;
          this.form = {
            enterpriseName: "",
            contacts: "",
            // uscCode: "",
            mobile: "",
            adSpace: "",
          };
          this.selectInfo.forEach((item, index) => {
            item.check = false;
            console.log(item);
            item.children.forEach((it, i) => {
              it.check = false;
            });
          });
          // 您提交的信息我们已收到，请耐心等待审核。
        } else {
          this.clauseMsg = true;
          this.Msg = res.message;
        }
      });
    },
    chengeRadio1(i, v) {
      this.selectInfo.forEach((item, index) => {
        if (i == index) {
          item.check = !item.check;
        }
      });
    },
    chengeRadio2(index, i) {
      this.selectInfo[index].children.forEach((e, k) => {
        if (i == k) {
          e.check = !e.check;
        }
      });
    },
    chenge(v) {
      // console.log(v);
    },
    // 调查问卷数据
    getQuestData() {
      getQuestionnaireById(1).then((res) => {
        if (res.success) {
          this.info = JSON.parse(res.result.content);
        } else {
          this.info = {};
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped lang="less">
.display-area-banner {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  padding: 20px 0;
  div.banner {
    .banner-content {
      text-align: left;
      margin: 0;
    }
    .banner_title {
      //   font-size: 37px;
      color: #ffffff;
      margin-top: 25px;
      font-weight: 400;
    }
    .banner_desc {
      //   font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 30px;
      line-height: 28px !important;
      overflow: hidden;
      text-overflow: ellipsis; //文本溢出显示省略号
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .banner_but {
      margin-top: 30px;
      a {
        color: #fff;
      }
      .but {
        border: 1px solid #fff;
        padding: 6px 50px;
        text-align: left;
        display: inline-block;
        border-radius: 20px;
        cursor: pointer;
        margin: 0;
      }
    }
  }
}
// 边框
.border_item {
  border: 1px solid #ededed;
  position: relative;
  margin: 80px 0 10px;
  padding: 80px 5%;
  .border_title {
    position: absolute;
    top: -23px;
    left: -50px;
    background-color: #fff;
    padding: 10px;
    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 80px;
      font-weight: 400;
      color: #e7e7e7;
      height: 60px;
    }
    em {
      display: inline-block;
      vertical-align: middle;
      font-size: 28px;
      font-weight: 400;
      color: #ff6600;
      font-style: normal;
      height: 100%;
      height: 60px;
      margin-left: 15px;
    }
  }
}
.jump {
  position: relative;
  .jump_item {
    color: #3173e1;
    font-size: 14px;
    text-decoration: underline;
    position: absolute;
    top: 0px;
    left: 100px;
  }
}
.expo_desc {
  .content {
    font-size: 18px;
    font-weight: 400;
    color: #505050;
    text-align: left;

    line-height: 48px;
    em {
      font-size: 22px;
      color: red;
      font-style: normal;
    }
  }
}
.check_desc {
  color: #8b8b8b;
  font-size: 14px;
  margin: 0;
}
.child_con {
  display: flex-start;
  flex-flow: row wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 10px;
  .child_item {
    display: inline-block;
    margin-right: 10px;
    min-width: calc(100% / 6);
    .form-check-label {
      color: #8b8b8b;
    }
  }
}
.form_con {
  text-align: left;
  margin-bottom: 20px;
  .form_title {
    text-align: left;
    font-size: 16px;
    color: #272727;
    margin: 20px 0;
    span {
      &:nth-child(2) {
        color: silver;
        font-size: 14px;
        margin-left: 6px;
      }
    }
  }
  .col-form-label {
    text-align: right;
  }
  /deep/.form-check {
    margin: 0 20px;
    padding: 0;
  }
  .form-group {
    i {
      color: red;
    }
    label {
      font-size: 14px;
      color: #707070;
      padding: 0px;
    }
    .invalid-feedbac {
      display: none;
      color: red;
    }
    .pro_title {
      font-size: 14px;
      color: #707070;
    }
  }
}
.but {
  text-align: left;
  margin-top: 10px;
  margin-left: 10%;
  p.error_msg {
    color: red;
  }
  div {
    padding: 20px;
    font-size: 14px;
    color: #b5b5b5;
    p {
      margin: 0;
    }
  }
  .btn-primary {
    padding-left: 50px;
    padding-right: 50px;
    background: linear-gradient(-9deg, #ff7210, #f4bb1e);
    border-radius: 6px;
    border: none;
  }
}
.modal-body {
  text-align: center;
  padding-bottom: 30px;
  div {
    color: #b88857;
    font-size: 20px;
  }
  p {
    color: #606060;
    font-size: 14px;
  }
  span {
    color: red;
    font-size: 14px;
  }
}
.toast-body {
  color: red;
}
.modal {
  //   top: 10vh;
  &.active {
    display: block;
  }
}
.modal-backdrop {
  display: none;
  &.active {
    display: block;
  }
}
.box {
  padding: 30px 10px;
  h4 {
    color: #fff;
    padding: 0px 0 20px 0;
  }
  .code {
    background-size: cover;
    padding: 20px 0px;
    img {
      width: 100%;
      padding: 0 50px;
    }
    p {
      color: #fff;
      font-size: 14px;
      margin: 10px 0 0 0;
    }
  }
}
.btn-secondary {
  background-color: #ff6600;
  border-color: #ff6600;
}
.getCode {
  input {
    display: inline-block;
    color: #175199;
    width: 60%;
  }
  span {
    padding: 10px 20px;
    display: inline-block;
    color: #175199;
    font-size: 14px;
    cursor: pointer;
  }
}
.form_con .form-group .form-check-input {
  margin-top: 0.5rem;
}
textarea {
  border-color: #ced4da;
}
.modal-header,
.modal-footer {
  border: none;
  text-align: center;
}
.modal-dialog {
  margin: 20vh auto;
}
.adv_seat_con {
  margin: 30px 0;
  .aseat_title {
    font-size: 20px;
    color: #999999;
    text-align: center;
    margin: 30px 0;
    em {
      width: calc(calc(100% - 200px) / 2);
      border: 2px solid #f6f6f6;
      background-color: #f6f6f6;
      display: inline-block;
      vertical-align: middle;
    }
    span {
      font-size: 18px;
      font-weight: bold;
      color: #ff6600;
      padding: 0 20px;
    }
  }
  .aseat_content {
    .con_image {
      padding-left: 10px;
      position: relative;
      img {
        width: 100%;
      }
      ul {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        li {
          position: absolute;
          // border: 1px solid #3173e1;
          width: 2.5%;
          border-radius: 50%;
          height: 2.5%;
          cursor: pointer;
          &:hover {
            .desc {
              display: block;
            }
          }
          .desc {
            display: none;
            position: relative;
            span {
              position: absolute;
              top: -30px;
              left: -47px;
              // top: -112%;
              // left: -30%;
              font-size: 16px;
              color: #505050;
              display: inline-block;
              background-color: rgba(0, 0, 0, 0.5);
              color: #fff;
              font-size: 12px;
              min-width: 110px;
            }
            em {
              border: 8px solid transparent;
              border-top: 8px solid rgba(0, 0, 0, 0.5);
              // position: absolute;
              // top: ;
            }
          }
        }
      }
    }
    .adv_list_item {
      padding-left: 10px;
      ul {
        padding: 20px;
        display: flex;
        background: #eeeeee;
        // flex-flow: row wrap;
        flex-wrap: wrap;
        // justify-content: space-between;
        li {
          padding: 20px 50px 0px 0;
          span {
            vertical-align: middle;
            font-size: 18px;
            color: #464646;
          }
          em {
            display: inline-block;
            text-align: center;
            width: 24px;
            height: 24px;
            background: #ee1d23;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
@media (max-width: 750px) {
  .getCode {
    span {
      padding: 10px 10px;
    }
  }
}
.check_phone {
  display: none;
  font-size: 14px;
  color: red;
  &.active {
    display: block;
  }
}
</style>