<template>
  <div class="page">
    <section
      class="display-area-banner relative"
      :style="{ backgroundImage: `url(${banner})` }"
    >
      <div class="container">
        <div class="row banner" style="height: 160px">
          <div class="banner-content col-lg-12 col-md-12">
            <h1 class="banner_title">参展对象</h1>
            <h5 class="banner_desc">
              凡是从事特色食品、境外食品、肉制品及冻品、粮油及调味品、休闲食品、酒水饮料、烘焙食品、食品加工机械等相关食品行业企业均可报名参会。
            </h5>
          </div>
        </div>
      </div>
    </section>
    <section class="list_section relative section1">
      <div class="container">
        <h4>室内-展区展品详情</h4>
        <div class="list row">
          <ul class="tab_left col-lg-3 col-3">
            <li
              v-for="(item, index) in data1"
              :key="index"
              v-if="item.type == 2"
              :class="active1Index == index ? 'active' : ''"
              @click="changeIndex1(index, item.desc, item.url)"
            >
              <div class="list_con">
                <p class="title">
                  {{ item.title }}
                </p>
              </div>
            </li>
          </ul>
          <div
            class="tab_con col-lg-9 col-9"
            :style="{ backgroundImage: `url(${url1})` }"
          >
            <p>{{ desc1 }}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="list_section item2 relative">
      <div class="container">
        <h4>室外-展区展品详情</h4>
        <div class="list row">
          <ul class="tab_left col-lg-3 col-3">
            <li
              v-for="(item, index) in data2"
              :key="index"
              v-if="item.type == 1"
              :class="active2Index == index ? 'active' : ''"
              @click="changeIndex2(index, item.desc, item.url)"
            >
              <div class="list_con">
                <p class="title">
                  {{ item.title }}
                </p>
              </div>
            </li>
          </ul>
          <div
            class="tab_con col-lg-9 col-9"
            :style="{ backgroundImage: `url(${url2})` }"
          >
            <p>{{ desc2 }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banner: require("@/assets/img/exRules/banner.jpg"),
      active1Index: 0,
      active2Index: 0,
      data2: [
        {
          title: "综合展区",
          desc: "漯河市各县区特色食品、蔬果、特色农产品、有机食品、乳制品等。",
          type: "1",
          url: require("@/assets/img/exRules/back9.jpg"),
        },
        {
          title: "休闲食品展区",
          desc:
            "膨化品、烘焙品、见过、甜食、糖果、巧克力、饼干、蜜饯、谷物、果脯类、燕窝、营养食品系列等。",
          type: "1",
          url: require("@/assets/img/exRules/back8.jpg"),
        },
        {
          title: "酒水饮料展区",
          desc:
            "国产白酒、葡萄酒、啤酒、功能酒、进口酒、碳酸饮料、水、茶饮、果汁、常温奶品、功能饮料、咖啡饮料、乳制品等。",
          type: "1",
          url: require("@/assets/img/exRules/back4.jpg"),
        },
        {
          title: "烘焙食品展区",
          desc: "烘焙食品、烘焙原料、蛋糕奶油、烘焙辅料、特许加盟等。",
          type: "1",
          url: require("@/assets/img/exRules/back2.jpg"),
        },
        {
          title: "食品机械展区",
          desc:
            "食品加工机械、包装机械、包装材料及制品、辅助生产社保、冷冻、冷藏及保险设备、纸箱机械、塑料机械等。",
          type: "1",
          url: require("@/assets/img/exRules/back7.jpg"),
        },
      ],
      data1: [
        /* 
          type:1 室外展区
          type:2 室内展区
        */
        {
          title: "河南省特色食品展区",
          desc: "主要展销河南省辖市具有代表性的特色食品、农产品、餐饮食材等。",
          type: "2",
          url: require("@/assets/img/exRules/back1.jpg"),
        },
        {
          title: "境外食品展区",
          desc:
            "境外酒类产品、咖啡类产品、休闲食品、保健品、橄榄油、食品添加剂等。",
          type: "2",
          url: require("@/assets/img/exRules/back3.jpg"),
        },
        {
          title: "肉制品及冻品展区",
          desc:
            "香肠、火腿制品、冷冻冰鲜肉、分割肉及肉制调理食材、冷冻/速冻、水产调理食材及深加工制品等。",
          type: "2",
          url: require("@/assets/img/exRules/back6.jpg"),
        },
        {
          title: "粮油调味品展区",
          desc:
            "大米、小米、面粉、五谷杂粮、食用油、各种酱油、鸡精等调味类产品。",
          type: "2",
          url: require("@/assets/img/exRules/back5.jpg"),
        },
      ],
      productType: "综合展区",
      form: {
        size: 16,
        current: 1,
      },
      desc1: "",
      url1: "",
      desc2: "",
      url2: "",
    };
  },
  methods: {
    changeIndex2(i, v, u) {
      this.active2Index = i;
      this.desc2 = v;
      this.url2 = u;
    },
    changeIndex1(i, v, u) {
      this.active1Index = i;
      this.desc1 = v;
      this.url1 = u;
    },
  },
  created() {
    this.changeIndex1(0, this.data1[0].desc, this.data1[0].url);
    this.changeIndex2(0, this.data2[0].desc, this.data2[0].url);
  },
};
</script>

<style scoped lang="less">
.display-area-banner {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  padding: 20px 0;
  div.banner {
    .banner-content {
      text-align: left;
      margin: 0;
    }
    .banner_title {
      //   font-size: 37px;
      color: #ffffff;
      margin-top: 25px;
    }
    .banner_desc {
      color: #ffffff;
      font-weight: 400;
      padding: 10px 20px;
      background-color: rgba(51, 109, 144, 0.5);
      border-radius: 10px;
    }
  }
}
.list_section {
  margin-top: 40px;
  h4 {
    margin: 30px 0;
  }
  .list {
    height: 560px;
    padding: 0 10px;
    ul {
      border: 1px solid #eee;
      padding: 0;
      li {
        height: calc(560px / 4);
        &:hover,
        &.active {
          .list_con {
            background: linear-gradient(90deg, #eb6c15, #f4b81e);
            p.title {
              color: #fff;
            }
          }
        }
        // margin: 10px 0;
        .list_con {
          height: 100%;
          padding-top: 60px;
          // line-height: calc(560px / 4);
          border-bottom: 1px solid #eeeeee;
          // min-height: 270px;
          background-color: #f9f9f9;
          cursor: pointer;
        }
        img {
          width: 100%;
          padding: 10px;
        }
        p.title {
          font-size: 16px;
          color: #212121;
          text-align: center;
          margin: 0;
          padding: 0 2px;
        }
      }
    }
    .tab_con {
      height: 100%;
      background-size: cover;
      position: relative;
      p {
        position: absolute;
        left: 0;
        bottom: -16px;
        padding: 20px;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        text-align: left;
      }
    }
  }
}
.item2.list_section {
  margin: 100px 0 40px 0;
  .list {
    ul {
      li {
        height: calc(560px / 5) !important;
        .list_con {
          // line-height: calc(560px / 4);
          padding-top: 40px;
        }
      }
    }
  }
}
@media (max-width: 750px) {
  .list_con {
    // min-height: 330px !important;
  }
  .banner_desc{
      font-size: 14px;
      line-height: 22px !important;
  }
  .display-area-banner div.banner .banner_title{
    margin-top: 0;
  }
}
</style>