<template>
  <div class="page">
    <section
      class="display-area-banner relative"
      :style="{ backgroundImage: `url(${banner})` }"
    >
      <div class="container">
        <div class="row banner" style="height: 160px">
          <div class="banner-content col-lg-12 col-md-12">
            <h1 class="banner_title">展商名录</h1>
            <h5 class="banner_desc">
              拟邀请150家以上世界500强、国内食品百强企业和知名品牌企业参展。
            </h5>
          </div>
        </div>
      </div>
    </section>
    <section class="search">
      <div class="container">
        <div class="search_con">
          <div class="input-group flex-nowrap">
            <div class="input-group-prepend">
              <span
                class="input-group-text iconfont iconsearch"
                id="addon-wrapping"
              ></span>
            </div>
            <input
              v-model="key"
              type="text"
              class="form-control"
              placeholder="请输入商家关键字"
              aria-label="Username"
              aria-describedby="addon-wrapping"
            />
            <div class="but">
              <span @click="searchData">搜索</span>
              <span @click="resetData">重置</span>
            </div>
          </div>
        </div>
        <p  v-if="!typeFlag && data.length > 0">以下是为您搜索到的结果</p>
      </div>
    </section>
    <section class="group_section relative" v-if="typeFlag">
      <div class="container" style="padding: 0">
        <div class="group">
          <div class="group_title">请选择分类</div>
          <ul class="group_list">
            <li
              :class="activeIndex == index ? 'active' : ''"
              v-for="(item, index) in typeList"
              :key="index"
              @click="changeTypeIndex(item.name, index)"
            >
              <span>{{ item.name }}</span>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="list_section relative">
      <div class="container">
        <div class="list" v-if="data.length > 0">
          <ul class="row">
            <li
              class="col-12"
              v-for="(item, index) in data"
              :key="index"
              @click="openWeb('/exhibitorDetails?id=' + item.id)"
            >
              <div class="list_con">
                <div class="logo">
                  <img v-if="item.logo" :src="item.logo" alt="logo加载失败" />
                  <p v-else>
                    {{ item.name }}
                  </p>
                </div>
                <div class="right">
                  <p class="title">
                    {{ item.name }}
                  </p>
                  <div class="type_but">
                    <span>{{ item.exhibitorsArea }}</span>
                    <span
                      ><i class="iconfont iconshangjia"></i>展位{{
                        item.exhibitorsBooth
                      }}</span
                    >
                  </div>
                  <div class="desc">
                    <p>{{ item.introduction }}</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="no_more" v-else>
          <i class="iconfont iconweb__zanwujilu"> </i>
          <p>数据正在更新中，请持续关注哟~</p>
        </div>
        <div
          class="more"
          @click="addCur"
          v-if="total > 8 && total != data.length"
        >
          <div>
            <i class="iconfont iconjiazai"></i>
            <span>加载更多</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getExhibitorsList, getexhibitorsInfoBySearch } from "@/api/index.js";
export default {
  data() {
    return {
      banner: require("@/assets/img/exhibitor/banner.jpg"),
      activeIndex: 0,
      typeList: [
        {
          name: "综合展区",
        },
        {
          name: "河南省特色食品",
        },
        {
          name: "境外食品",
        },
        {
          name: "肉制品及冻品",
        },
        {
          name: "粮油及调味品",
        },
        {
          name: "休闲食品",
        },
        {
          name: "酒水饮料",
        },
        {
          name: "烘焙食品",
        },
        {
          name: "食品加工机械",
        },
      ],
      data: [],
      productType: "综合展区",
      form: {
        size: 8,
        current: 1,
        isCheck:1
      },
      total: "",
      key: "",
      typeFlag: true,
    };
  },
  methods: {
    resetData() {
      this.typeFlag = true;
      this.productType = "综合展区";
      this.key = "";
      this.getData();
    },
    searchData() {
      this.typeFlag = false;
      getexhibitorsInfoBySearch({ name: this.key }).then((res) => {
        if (res.success) {
          this.data = res.result.records;
          this.total = res.result.total;
        } else {
          this.data = [];
        }
      });
    },
    openWeb(v) {
      this.$router.push(v);
    },
    addCur() {
      this.form.size = this.form.size + 8;
      this.getData();
    },
    changeTypeIndex(v, i) {
      this.activeIndex = i;
      this.productType = v;
      this.getData();
    },
    getData() {
      getExhibitorsList(this.productType, this.form).then((res) => {
        if (res.success) {
          this.data = res.result.records;
          this.total = res.result.total;
        } else {
          this.data = [];
        }
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped lang="less">
.display-area-banner {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  padding: 20px 0;
  div.banner {
    .banner-content {
      text-align: left;
      margin: 0;
    }
    .banner_title {
      //   font-size: 37px;
      color: #ffffff;
      margin-top: 25px;
    }
    .banner_desc {
      //   font-size: 16px;
      color: #ffffff;
      font-weight: 400;
      padding: 10px 20px;
      background-color: rgba(225, 43, 50, 0.5);
      border-radius: 10px;
    }
  }
}
.group_section {
  .group {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    margin: 20px 6px;
    .group_title {
      text-align: left;
      font-size: 16px;
      color: #3b3b3b;
      background-color: #f9f9f9;
      padding: 10px 20px;
    }
    ul.group_list {
      li {
        padding: 10px;
        display: inline-block;
        margin: 0 10px;
        cursor: pointer;
        &:hover,
        &.active {
          color: #eb6e15;
        }
        span {
          font-size: 14px;
        }
      }
    }
  }
}
.list_section {
  min-height: 40vh;
  .list {
    ul {
      li {
        padding: 0 6px;
        &.col-lg-3 {
          padding: 0 4px;
        }
        .list_con {
          background: linear-gradient(0deg, #f5f5f5, #ffffff);
          border-radius: 4px;
          border: 1px solid #eeeeee;
          cursor: pointer;
          padding-left: 20px;
          margin: 10px 0;
          .logo {
            width: 156px;
            max-height: 160px;
            overflow: hidden;
            display: inline-block;
            vertical-align: middle;
            padding: 2px 2px;
            border: 1px solid #eee;
            background-color: #fff;
            margin: 10px 0;
            p {
              color: #999;
              font-size: 14px;
              padding: 20px 10px;
              margin: 0;
            }
          }
          .right {
            width: calc(100% - 156px);
            display: inline-block;
            vertical-align: middle;
            padding: 10px 20px;
          }
          &:hover {
            box-shadow: 0px 0px 6px 0px rgba(39, 39, 39, 0.16);
          }
        }
        img {
          width: 100%;
          padding: 10px;
        }
        p.title {
          font-size: 16px;
          color: #393939;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 10px;
          padding: 4px 0px;
          text-align: left;
          margin: 0;
        }
        .type_but {
          text-align: left;
          padding: 2px 0 5px 0;
          span {
            text-align: center;
            border: 1px solid #eeeeee;
            border-radius: 4px;
            display: inline-block;
            padding: 2px 10px;
            font-size: 14px;
            color: #ff6600;
            &:nth-child(2) {
              margin-left: 10px;
              color: #fff;
              background-color: #ff4400;
              i {
                margin: 0 2px;
              }
            }
          }
        }
        .desc {
          font-size: 14px;
          color: #3b3b3b;
          line-height: 26px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-align: left;
          p {
            margin: 0;
          }
        }
      }
    }
  }
  .no_more {
    // height: 40vh;
    padding-top: 100px;
    color: #ddd;
    i {
      font-size: 100px;
    }
    p {
      margin: 20px;
    }
  }
  .more {
    margin: 10px 0;
    div {
      display: inline-block;
      padding: 6px 30px;
      border: 1px solid #ff6600;
      border-radius: 8px;
      cursor: pointer;
      i {
        color: #ff6600;
      }
      span {
        font-size: 16px;
        color: #ff6600;
      }
    }
  }
}
.search {
  margin-top: 10px;
  p{
    color: #999;
    margin: 20px 0;
    font-size: 14px;
  }
  .container {
    padding: 0 6px;
  }
  .search_con {
    // background: linear-gradient(-6deg, #fa8631, #ffde8c);
    background-image: url(../../assets/img/search_back.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    .input-group {
      width: 70%;
      margin: 0 auto;
    }
    .input-group-prepend {
      span {
        font-size: 1.5rem;
        line-height: 1rem;
        color: #cfcfcf;
      }
    }
    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #ccc;
      font-size: 14px;
    }
    .input-group-text {
      background-color: #fff;
      border-right: none;
    }
    .form-control {
      border-left: none;
      outline: none;
      border-radius: 0 4px 4px 0 !important;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .but {
      vertical-align: middle;
      span {
        background: #ffffff;
        border-radius: 4px;
        font-size: 16px;
        color: #eb7015;
        line-height: 26px;
        padding: 6px 40px;
        height: 100%;
        margin: 0 0 0 10px;
        display: inline-block;
        cursor: pointer;
        font-weight: 600;
        &:nth-child(2){
          color:#999;
        }
      }
    }
  }
}
@media (max-width: 750px) {
  .list_con {
    .logo {
      display: none !important;
    }
    .right {
      width: 100% !important;
    }
  }
  .banner_desc {
    font-size: 14px;
    line-height: 22px !important;
  }
  .display-area-banner div.banner .banner_title {
    margin-top: 20px;
  }

  .search {
    .search_con {
      .input-group {
        width: 95% !important;
      }
      .but {
        span {
          padding: 6px 10px !important;
        }
      }
    }
  }
}
</style>