<template>
  <div class="main">
    <span v-if="userInfo.userType == 1">
      <exhibitorsCenter></exhibitorsCenter>
    </span>
    <span v-else>
      <buyers></buyers>
    </span>
  </div>
</template>

<script>
import buyers from "./components/buyers";
import exhibitorsCenter from "./components/exhibitors_center";
export default {
  data() {
    return {
      userInfo: {},
    };
  },
  components: {
    buyers,
    exhibitorsCenter,
  },
  created() {
    if (window.sessionStorage.getItem("user")) {
      this.userInfo = JSON.parse(window.sessionStorage.getItem("user"));
    } else {
      this.userInfo = {};
    }
  },
};
</script>

<style>
</style>