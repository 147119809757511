<template>
  <div class="page">
    <div class="container">
      <div class="page_con">
        <div class="page_title">中国（漯河）食品博览会</div>
        <div class="page_item_con">
          <p>
            中国（漯河）食品博览会是由中国食品工业协会、中国商业联合会、中国食品和包装机械工业协会、中国食品土畜进出口商会共同主办，河南省食品工业协会、河南省博览事务局有限公司承办的食品类综合性会展，得到了河南省工信厅、河南省商务厅、河南省市场监管局等省直单位的大力支持，在国内具有较高知名度和较强影响力。
          </p>
          <p>
            会展举办地漯河市食品产业发达，是全国首家中国食品名城，孕育了全球最大的肉类加工企业万洲双汇集团，全国著名的农产品加工企业南街村集团，中国辣味休闲食品第一品牌卫龙食品等一批龙头企业；吸引了可口可乐、美国嘉吉、美国杜邦、中粮集团、台湾统一、旺旺集团等众多名企投资发展，食品工业已成为漯河市第一支柱产业。
          </p>
          <p>
            2003年起，漯河市以食为媒，连续19年举办食品博览会，取得了令人瞩目的成绩。“食博会”先后5次被商务部列为重点引导支持会展，被中国会展财富论坛评为“十大产业展览会”和“最具品牌价值展览会”。2018年，又被中国会展产业大会评为“改革开放40年，40个品牌展览会”之一；中国食品工业协会每年在大会期间发布食品产业信息数据，成为国内食品行业的“风向标”。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.page {
  background: #f9f9f9;
  padding: 30px 0;
  .page_con {
    background: #fff;
    padding: 30px 20px;
    .page_title {
      font-size: 18px;
      color: #595959;
      padding: 20px 0;
      border-bottom: 1px solid #eee;
    }
    .page_item_con {
      padding: 30px 10px;
      font-size: 14px;
      color: #595959;
      text-align: left;
      text-indent: 2rem;
      line-height: 28px;
    }
  }
}
</style>