<template>
  <div class="page">
    <section
      class="display-area-banner relative"
      :style="{ backgroundImage: `url(${banner})` }"
    >
      <div class="container">
        <img :src="static" alt="" />
      </div>
    </section>
    <section class="page_con">
      <div class="container">
        <div class="con_item row">
          <div class="left col-lg-9 col-12">
            <div class="left_title">
              <span>{{ data.name }}</span>
              <i>{{ data.productType }}</i>
            </div>
            <div class="left_con">
              <div v-for="(item, index) in data.path" :key="index">
                <img :src="item" alt="" />
              </div>
            </div>
          </div>
          <div class="right col-lg-3 col-12">
            <div class="ex_info">
              <div class="image">
                <img :src="exData.logo" alt="" />
              </div>
              <p @click="openWeb('/exhibitorDetails?id=' + exData.id)">
                {{ exData.name }}
              </p>
              <div class="text">
                <div>展位：{{ exData.exhibitorsBooth }}</div>
                <div>联系人：
                    <span v-if="exData.contact ">{{ exData.contact }}</span>
                    <span v-else>暂无</span>
                </div>
                <div>联系电话：{{ exData.contactPhone }}</div>
              </div>
            </div>
            <div class="pro_list">
              <div class="title">商家其他产品</div>
              <!-- {{exData.exhibitorsProductList}} -->
              <ul>
                <li
                  @click="openWeb('/proDetail?id=' + item.id)"
                  v-for="(item, index) in exData.exhibitorsProductList"
                  :key="index"
                >
                  <div :style="{ backgroundImage: `url(${item.path[0]})` }">
                    <!-- <img :src="item.path" alt="" /> -->
                  </div>
                  <p>
                    {{ item.name }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getproDataById, getproAndExDataById } from "@/api/index.js";
export default {
  data() {
    return {
      banner: require("@/assets/img/displayArea/porDetail/banner.jpg"),
      static: require("@/assets/img/displayArea/porDetail/static.png"),
      data: [],
      exData: [],
    };
  },
  methods: {
    openWeb(v) {
      this.$router.push(v);
    },
    getExData(v) {
      // exhibitorsId
      getproAndExDataById(v).then((res) => {
        if (res.success) {
          res.result.exhibitorsProductList.forEach((item) => {
            item.path = JSON.parse(item.path);
          });
          this.exData = res.result;
        } else {
          this.data = [];
        }
      });
    },
    getData(v) {
      getproDataById(v).then((res) => {
        if (res.success) {
          if (res.result.path) {
            res.result.path = JSON.parse(res.result.path);
          } else {
            res.result.path = "";
          }
          this.data = res.result;
          this.getExData(this.data.exhibitorsId);
        } else {
          this.data = [];
        }
      });
    },
  },
  created() {
    // this.getData();
  },
  watch: {
    $route: {
      handler() {
        this.getData(this.$route.query.id);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="less">
.display-area-banner {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  padding: 20px 0;
  img {
    width: 60%;
  }
}
.page_con {
  padding: 20px;
  background-color: #f9f9f9;
  .con_item {
    .left {
      border: 1px solid #eee;
      background-color: #fff;
      padding: 0 0 20px 0;
      margin-bottom: 10px;
      .left_title {
        background-color: #f9f9f9;
        border-bottom: 1px solid #eee;
        padding: 10px 20px;
        text-align: left;
        span {
          font-size: 18px;
          color: #666666;
        }
        i {
          font-size: 14px;
          color: #ffffff;
          display: inline-block;
          padding: 0px 10px;
          background-color: #ff6600;
          font-style: normal;
          margin-left: 10px;
          border-radius: 4px;
        }
      }
      .left_con {
        text-align: center;
        div {
          padding: 20px 40px 0 40px;
          img {
            width: 100%;
            //   margin: 20px;
          }
        }
      }
    }
    .right {
      .ex_info {
        padding: 20px;
        border: 1px solid #eee;
        background-color: #fff;
        .image {
          padding: 0 20px;
          img {
            width: 100%;
          }
        }
        p {
          font-size: 16px;
          color: #1952c6;
          cursor: pointer;
          margin-top: 10px;
        }
        div.text {
          border-top: 1px solid #eee;
          padding-top: 10px;
          text-align: left;
          font-size: 14px;
          color: #666666;
        }
      }
      .pro_list {
        margin-top: 10px;
        border: 1px solid #eee;
        background-color: #fff;
        .title {
          border-bottom: 1px solid #eee;
          font-size: 16px;
          color: #333333;
          padding: 6px 20px;
          background-color: #f9f9f9;
          text-align: left;
        }
        ul {
          li {
            padding: 10px;
            cursor: pointer;
            div {
              height: 220px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              border: 1px solid #eee;
            }
            p {
              font-size: 14px;
              color: #333333;
              margin: 10px 0 0 0;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 770px) {
  .display-area-banner {
    img {
      width: 100%;
    }
  }
  .right {
    padding: 0;
  }
}
</style>