<template>
  <div class="page">
    <div class="container">
      <div class="page_con">
        <div class="page_title">中国（漯河）食品博览会 - 活动安排</div>
        <div
          class="page_item_con row"
          v-for="(item, index) in data"
          :key="index"
        >
          <div class="left col-2">
            <p>
              {{ item.meetingType }}
            </p>
          </div>
          <div class="right col-10">
            <ul class="row">
              <li class="col-12" v-for="(it, i) in item.meetings" :key="i">
                <div class="list_con">
                  <img :src="it.titleImg" alt="" />
                  <div class="text">
                    <p>{{ it.meetingName }}</p>
                    <p>{{ it.meetingTime }}</p>
                    <p>{{ it.meetingPlace }}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMeetingData } from "@/api/index.js";
export default {
  data() {
    return {
      data: [

      //   {
      //     date: "综合活动",
      //     list: [
      //       {
      //         url: require("@/assets/img/plan/plan1.jpg"),
      //         time: "时间：5月15日18:30-20:00",
      //         site: "地点：漯河市迎宾馆迎宾厅",
      //       },
      //       {
      //         url: require("@/assets/img/plan/plan2.jpg"),
      //         time: "时间：5月16日9:00-9:30",
      //         site: "地点：漯河市国际会展中心大剧院",
      //       },
      //     ],
      //   },
      //   {
      //     date: "论坛和学术交流活动",
      //     list: [
      //       {
      //         title:
      //           "2021年度中国食品工业经济运行发布会暨中国食品产业发展高峰论坛",
      //         url: require("@/assets/img/plan/plan3.jpg"),
      //         time: "时间：5月16日9:30-11:30",
      //         site: "地点：漯河市国际会展中心大剧院",
      //       },

      //       {
      //         title: "RCEP论坛",
      //         url: require("@/assets/img/plan/plan4.jpg"),
      //         time: "时间：5月16日15：00-17：00",
      //         site: "地点：漯河市国际会展中心报告厅",
      //       },
      //       {
      //         title: "2022中国（漯河）食品机械产业技术发展大会",
      //         url: require("@/assets/img/plan/plan5.jpg"),
      //         time: "时间：5月17日8：30-11:30",
      //         site: "地点：漯河市国际会展中心报告厅",
      //       },

      //       {
      //         title: "食品专家漯河行",
      //         url: require("@/assets/img/plan/plan6.jpg"),
      //         time: "时间：5月16日—18日",
      //         site: "地点：漯河市有关企事业单位",
      //       },
      //       /* {
      //         url: require("@/assets/img/plan/plan14.jpg"),
      //         time: "时间：5月16日 14:00-17:00",
      //         site: "地点：漯河国际会展中心",
      //       }, */
      //     ],
      //   },
      //   {
      //     date: "采购对接活动",
      //     list: [
      //       {
      //         title: "2022同道联邦休闲食品厂商对接会",
      //         url: require("@/assets/img/plan/plan7.jpg"),
      //         time: "时间：5月15日全天",
      //         site: "地点：皇宫酒店",
      //       },
      //       {
      //         title: "2022雅图传媒休闲食品厂商对接会",
      //         url: require("@/assets/img/plan/plan8.jpg"),
      //         time: "时间：5月15日全天",
      //         site: "地点：大东海金源酒店",
      //       },
      //       {
      //         title: "2022全食汇展休闲食品厂商对接会",
      //         url: require("@/assets/img/plan/plan9.jpg"),
      //         time: "时间：5月15日全天",
      //         site: "地点：万家和酒店",
      //       },
      //       {
      //         title: "中国（漯河）食品博览会重点产品国际推介会",
      //         url: require("@/assets/img/plan/plan10.jpg"),
      //         time: "时间：5月17日15:00-17:30",
      //         site: "地点：漯河市国际会展中心报告厅",
      //       },
      //       {
      //         title: "漯河特色食品推介会",
      //         url: require("@/assets/img/plan/plan11.jpg"),
      //         time: "时间：5月16日—17日全天",
      //         site: "地点：漯河市国际会展中心广场",
      //       },
      //     ],
      //   },
      //   {
      //     date: "投资洽谈活动",
      //     list: [
      //       {
      //         title:
      //           "漯河市情说明暨项目签约仪式—《食博会辉煌二十年》邮票首发式  ",
      //         url: require("@/assets/img/plan/plan12.jpg"),
      //         time: "时间：5月16日10:30-11:30",
      //         site: "地点：漯河市国际会展中心报告厅",
      //       },
      //       {
      //         title: "会见活动",
      //         url: require("@/assets/img/plan/plan13.jpg"),
      //         time: "时间：5月16日-17日",
      //         site: "地点：各会见厅",
      //       },
      //       {
      //         title: "实地考察",
      //         url: require("@/assets/img/plan/plan14.jpg"),
      //         time: "时间：5月16日-18日",
      //         site: "地点：各县区",
      //       },
      //     ],
      //   },
      //   {
      //     date: "形象展示活动",
      //     list: [
      //       {
      //         title: "“食博会辉煌二十年”食品文化展",
      //         url: require("@/assets/img/plan/plan15.jpg"),
      //         time: "时间：5月16日-18日",
      //         site: "地点：漯河国际会展中心圆厅",
      //       },
      //       {
      //         title: "食博会二十年“品味中原”系列访谈",
      //         url: require("@/assets/img/plan/plan16.jpg"),
      //         time: "时间：5月16日-17日",
      //         site: "地点：在各大新闻媒体和网络媒体播出",
      //       },
      //     ],
      //   },
      
      ],
    };
  },
  methods: {
    getData() {
      getMeetingData().then((res) => {
        this.data = res.result;
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped lang="less">
.page {
  background: #f9f9f9;
  padding: 20px 0;
  .page_con {
    background: #fff;
    padding: 10px 20px;
    .page_title {
      font-size: 18px;
      color: #595959;
      padding: 20px 0;
      text-align: left;
      border-bottom: 1px solid #eee;
    }
    .page_item_con {
      font-size: 14px;
      color: #595959;
      text-align: left;
      line-height: 28px;
      // min-height: 50vh;
      .left {
        padding: 20px 0 20px 15px;
        p {
          background: #ff6600;
          color: #fff;
          text-align: center;
          padding: 10px 0;
          font-size: 16px;
          font-weight: 600;
        }
      }
      .right {
        padding: 20px 15px;
        border-left: 2px solid #f3f3f3;
        ul {
          li {
            margin-bottom: 20px;
            .list_con {
              background: #fafafa;
              border-radius: 10px;
              padding: 10px;
              img {
                vertical-align: middle;
              }
              .text {
                display: inline-block;
                vertical-align: middle;
                padding-left: 20px;
                p {
                  margin: 0;
                  font-size: 16px;
                  word-wrap:break-word;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 490px) {
.page {
    .page_con {
      .page_item_con {
        .right {
          ul {
            li {
              .list_con {
                .text {
                  width: 60%;
                }
              }
            }
          }
        }
      }
    }
}
}
@media (max-width: 490px) {
  .page {
    .page_con {
      .page_item_con {
        .left {
          padding-left: 0;
          p {
            font-size: 14px;
            font-weight: 400;
          }
        }
        .right {
          ul {
            li {
              .list_con {
                .text {
                  padding-left: 0px;
                  p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                  }
                }
              }
              img {
                width: 100%;
                margin-bottom: 6px;
              }
            }
          }
        }
      }
    }
  }
}
</style>