<template>
  <div class="page">
    <div class="container">
      <div class="page_con">
        <div class="page_title">联系我们</div>
        <div class="page_item_con">
          <p>中国（漯河）食品博览会组委会</p>
          <!-- <p>联系人：李伯顺</p> -->
          <p>联系电话：0371-63577569；15903649003</p>
          <!-- <p>邮箱：boshunli@hnexpo.gov.cn</p> -->
          <p>邮箱：boshunli@hnexpo.org.cn</p>
        </div>
      </div>
      <div class="page_con">
        <div class="page_title">展馆位置</div>
        <div class="page_item_con">
          <div class="map">
            <div id="GDMap"></div>
          </div>
          <p>会展中心地址：漯河市郾城区嵩山东支路</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AMap from "AMap"; //在页面中引入高德地图
export default {
  methods: {
    loadmap() {
      let marker,
        map = new AMap.Map("GDMap", {
          resizeEnable: true,
          zoom: 15,
          center: [114.015295,33.59963],
        });
      marker = new AMap.Marker({
        icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
        position: [114.015295,33.59963],
      });
      marker.setLabel({
        //label默认蓝框白底左上角显示，样式className为：amap-marker-label
        offset: new AMap.Pixel(-70, -30), //修改label相对于maker的位置
        content: "漯河国际会展中心",
      });
      marker.setTitle("地址：漯河市郾城区嵩山东支路");
      marker.setMap(map);
    },
  },
  mounted() {
    this.loadmap();
  },
};
</script>

<style scoped lang="less">
.page {
  background: #f9f9f9;
  padding: 30px 0;
  .map {
    width: 97%;
    height: 400px;
    padding-top: 15px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px;
    overflow: hidden;
    background: #f0f0f0;
    text-align: center;
    // margin-top: 20px;
    #GDMap {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      margin: 10px;
      width: 95%;
      display: inline-block;
      height: 350px;
      border: 4px solid #fff;
    }
  }
  .page_con {
    background: #fff;
    padding: 10px 10px 0 10px;
    margin-bottom: 20px;
    .page_title {
      text-align: left;
      font-size: 18px;
      color: #595959;
      padding: 20px;
      border-bottom: 1px solid #eee;
    }
    .page_item_con {
      padding: 30px 10px;
      font-size: 14px;
      color: #595959;
      text-align: left;
      line-height: 28px;
      &:nth-child(2) {
        p {
          margin-top: 20px;
        }
      }
    }
  }
}
@media (max-width:750px) {
  .page_item_con{
    padding: 20px 0;
  }
}
</style>