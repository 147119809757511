// 统一请求路径前缀在libs/axios.js中修改
import { getRequest, postRequest, putRequest, deleteRequest, importRequest, getRequestWithNoToken, postRequestWithNoToken, putRequestWithNoToken } from '@/libs/axios';

import Qs from 'qs'

// 首页倒计时
export const getTimLimit = (params) => {
    return getRequestWithNoToken("/exhibitors/timLimit", params)
}
// 获取主题展区 商品列表
export const getExhibitorsProduct = (productType, params) => {
    return getRequestWithNoToken(`/exhibitorsProduct/productType/${productType}`, params)
}
// 获取调查问卷
export const getQuestionnaireById = (id, params) => {
    return getRequestWithNoToken(`/xboot/survey/questionnaire/${id}`, params)
}
// 获取展商名录列表
export const getExhibitorsList = (area, params) => {
    return getRequestWithNoToken(`/exhibitors/Area/${area}`, params)
}
// 多条件分页获取展品
export const getProductByExhibitorsId = (params) => {
    return getRequestWithNoToken(`/exhibitorsProduct/`, params)
}
// 通过id获取展商详情
export const getExhibitorsById = (id, params) => {
    return getRequestWithNoToken(`/exhibitors/${id}`, params)
}
// 观众报名
export const exhibitorsAudienceApply = (params) => {
    return postRequestWithNoToken(`/exhibitors/audienceApply`, params)
}
// 参展商报名
export const exhibitorsBuyersApply = (params) => {
    return postRequestWithNoToken(`/exhibitors/buyersApply`, params)
}
// 获取登录验证码
export const getLoginCode = (mobile, params) => {
    return getRequestWithNoToken(`/common/captcha/sendFrontLoginSms/${mobile}`, params)
}
// 失焦查重手机号
export const checkMobile = (params) => {
    return postRequestWithNoToken(`/exhibitors/mobile`, params)
}
// 参展商报名
export const exhibitorsApply = (params) => {
    return postRequestWithNoToken(`/exhibitors/apply`, params)
}
// 登录
export const authLogin = (params) => {
    return postRequestWithNoToken(`/auth/login`, params)
}
/*

1 参展商
2 采购商
3 观众

*/
// 更新展商信息
export const putExhibitors = (params) => {
    return putRequestWithNoToken(`/exhibitors`, params)
}
// 删除展品
export const deletePro = (params) => {
    return deleteRequest(`/exhibitorsProduct`, params)
}
// 编辑展品
export const putPro = (params) => {
    return putRequest(`/exhibitorsProduct`, params)
}
// 添加展品
export const addPro = (params) => {
    return postRequest(`/exhibitorsProduct/addProduct`, params)
}
// 获取观众或采购商信息
export const getPersonalInfo = (params) => {
    return getRequest(`/exhibitors/personalInfo`, params)
}
// 多条件分页获取新闻  
export const getInfoData = (params) => {
    params["orders[0].asc"] = false
    params["orders[0].column"] = "create_time"
    let cur = Qs.stringify(params)
    return getRequest(`/information?${cur}`)
}
// 根据id获取新闻详情
export const getInfoById = (id, params) => {
    return getRequestWithNoToken(`/information/${id}`, params)
}
// 获取首页精品展商
export const getProByRe = (params) => {
    return getRequestWithNoToken(`/exhibitors/recommendedExhibitors/4`, params)
}
// 观众报名
export const audienceApply = (params) => {
    return postRequestWithNoToken(`/exhibitors/audienceApply`, params)
}
// 获取展商详情
export const getExhibitorDetails = (params) => {
    return getRequest(`/exhibitors/exhibitorDetails`, params)
}
// 添加展商人员
export const addExhibitorsUser = (params) => {
    return postRequestWithNoToken(`/exhibitorsUser`, params)
}
// 展商人员分页获取
export const getExhibitorsUser = (params) => {
    return getRequest(`/exhibitorsUser/`, params)
}
// 删除参展人员
export const deleteExhibitorsUser = (params) => {
    return deleteRequest(`/exhibitorsUser`, params)
}
// 通过id获取展品详情
export const getproDataById = (id,params) => {
    return getRequest(`/exhibitorsProduct/${id}`, params)
}
// 根据id获取详情页数据
export const getproAndExDataById = (id,params) => {
    return getRequest(`/exhibitors/detailExhibitorsAndProduct/${id}`, params)
}
// 统计
export const statisticsData = (params) => {
    return postRequestWithNoToken(`/statistics/statistics/exhibitor`, params)
}
// 搜索商家
export const getexhibitorsInfoBySearch = (params) => {
    return getRequest(`/exhibitors/exhibitorsInfo/byPage`, params)
}
// 搜索展品
export const getexhibitorsProductInfoBySearch = (params) => {
    return getRequest(`/exhibitorsProduct/search`, params)
}

// 企业报名
export const enterpriseDataApply = (params) => {
    return postRequestWithNoToken(`/enterpriseApply/apply`, params)
}


// 获取会议活动数据
export const getMeetingData = (params) => {
    return getRequest(`/meeting/pcMeetings`, params)
}












