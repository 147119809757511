import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import displayArea from '../views/displayArea/displayArea.vue'//主题展区
import signUp from '../views/spectator/signUp.vue' //在采购商报名
import advSignUp from '../views/spectator/advSignUp.vue' //广告招商报名
import exhibitor from '../views/spectator/exhibitor.vue' //展商名录
import exhibitorDetails from '../views/spectator/exhibitorDetails.vue' //展商详情页
import exRules from '../views/exhibitor/exRules.vue' //参展对象
import boothLayout from '../views/exhibitor/boothLayout.vue' //展区平面图
import exSignUp from '../views/exhibitor/exSignUp.vue' //参展报名
import aboutEx from '../views/overview/aboutEx.vue' //关于展会
import org from '../views/overview/org.vue' //组织机构
import login from '../views/login/login.vue' //登录
// import buyers from '../views/manageCenter/buyers.vue' //观众  采购商管理中心
// import exhibitors_center from '../views/manageCenter/exhibitors_center.vue' //展商管理中心
import center from '../views/manageCenter/center.vue' //管理中心
import plan from '../views/overview/plan.vue' //会议安排
import previousExhibitions from '../views/overview/previousExhibitions.vue' //历届展会
import infoDetails from '../views/infoDetails.vue'  //新闻详情页
import contactUs from '../views/contactUs/contactUs.vue'  //联系我们
import news from '../views/news/news.vue'  //新闻列表
import introduce from '../views/introduce/introduce.vue'  //漯河风采
import proDetail from '../views/displayArea/proDetail.vue'//展品详情
// 
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/index'// 重定向
  },
  {
    path: '/index',
    name: 'index',
    component: index,
    meta: {
      title: '首页'
    }
  },
  {
    path: '/displayArea',
    name: 'displayArea',
    component: displayArea,
    meta: {
      title: '主题展区'
    }
  },
  {
    path: '/signUp',
    name: 'signUp',
    component: signUp,
    meta: {
      title: '采购商报名'
    }
  },
  {
    path: '/advSignUp',
    name: 'advSignUp',
    component: advSignUp,
    meta: {
      title: '广告招商报名'
    }
  },
  {
    path: '/exhibitor',
    name: 'exhibitor',
    component: exhibitor,
    meta: {
      title: '展商名录'
    }
  },
  {
    path: '/exhibitorDetails',
    name: 'exhibitorDetails',
    component: exhibitorDetails,
    meta: {
      title: '展商详情'
    }
  },
  {
    path: '/exRules',
    name: 'exRules',
    component: exRules,
    meta: {
      title: '参展对象'
    }
  },
  {
    path: '/boothLayout',
    name: 'boothLayout',
    component: boothLayout,
    meta: {
      title: '展区平面图'
    }
  },
  {
    path: '/exSignUp',
    name: 'exSignUp',
    component: exSignUp,
    meta: {
      title: '参展报名'
    }
  },
  {
    path: '/aboutEx',
    name: 'aboutEx',
    component: aboutEx,
    meta: {
      title: '关于展会'
    }
  },
  {
    path: '/org',
    name: 'org',
    component: org,
    meta: {
      title: '组织机构'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      title: '登录'
    }
  },
  /* {
    path: '/buyers',
    name: 'buyers',
    component: buyers,
    meta: {
      title: '个人中心',
      // // 是否需要鉴权
      requiresAuth: true
    }
  },
  {
    path: '/exhibitors_center',
    name: 'exhibitors_center',
    component: exhibitors_center,
    meta: {
      title: '个人中心',
      requiresAuth: true
    }
  }, */
  {
    path: '/center',
    name: 'center',
    component: center,
    meta: {
      title: '个人中心',
      requiresAuth: true
    }
  }, 
  // 
  {
    path: '/plan',
    name: 'plan',
    component: plan,
    meta: {
      title: '会议安排'
    }
  },
  {
    path: '/previousExhibitions',
    name: 'previousExhibitions',
    component: previousExhibitions,
    meta: {
      title: '历届展会'
    }
  },
  {
    path: '/infoDetails',
    name: 'infoDetails',
    component: infoDetails,
    meta: {
      title: '详情'
    }
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: contactUs,
    meta: {
      title: '联系我们'
    }
  },
  {
    path: '/news',
    name: 'news',
    component: news,
    meta: {
      title: '新闻'
    }
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: introduce,
    meta: {
      title: '漯河风采'
    }
  },
  {
    path: '/proDetail',
    name: 'proDetail',
    component: proDetail,
    meta: {
      title: '展品详情'
    }
  },
  // 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router


// 有的路由需要鉴权，有的不需要
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  // 设置文档标题
  var title = to.meta.title + ' - 中国（漯河）食品博览会' || '中国（漯河）食品博览会';
  window.document.title = title;
  // some  只要有一个符合条件  就返回真值
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 需要鉴权
    if (sessionStorage.getItem("accessToken")) {
      next()
    } else {
      // sessionStorage.clear();
      // 更新vuex中的用户信息
      // store.commit('set_enter', '');
      // store.commit("set_user", ""); 
      next({
        path: '/login',
        // 在登录成功后，回到原来的页面
        query: { redirect: to.fullPath }
      })
    }
  } else {
    next()
  }
  next()
})
