<template>
  <div class="page_nav">
    <div class="container">
      <div class="login">
        <div @click="openWeb('/center')" v-if="userInfo && userInfo.userType">
          <i class="iconfont iconweidenglutouxiang"></i>
          <span>{{ userInfo.nickName }}</span>
        </div>
        <div @click="openWeb('/login')" v-else>
          <i class="iconfont iconweidenglutouxiang"></i>
          <span>登录</span>
        </div>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <a class="navbar-brand" @click="openWeb('/index')">
          <img :src="logo" alt="logo加载失败" title="中国（漯河）食品博览会" />
        </a>
        <div class="section_top">
          <div class="time time1" v-if="TimLimit < 0">食博会5月16日盛大开幕！</div>
          <div class="time" v-else>
            距展会开幕还有
            <span :style="{ backgroundImage: `url(${djs})` }">
              {{ TimLimit }}
            </span>

            天
          </div>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          :aria-expanded="m_nav_show"
          @click="changeNav"
          :class="m_nav_show ? 'collapsed' : ''"
        >
          <!-- 
          data-toggle="collapse"
          data-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-label="Toggle navigation" -->
          <span class="navbar-toggler-icon"></span>
        </button>

        <div
          class="collapse navbar-collapse"
          :class="showFlage ? 'show' : ''"
          id="navbarTogglerDemo02"
        >
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <router-link
              v-for="(item, index) in navData"
              :key="index"
              tag="li"
              :to="item.url"
              class="nav-item"
              :class="activeIndex == index ? 'active' : ''"
            >
              <span>{{ item.name }}</span>
              <i class="iconfont iconxiala1" v-if="item.children"></i>
              <!-- </a> -->
              <!-- <a
                class="nav-link dropdown-toggle"
                :class="activeIndex == index ? 'active' : ''"
                href="#"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                v-else
                >{{ item.name }} -->
              <div class="dropdown-menu" v-if="item.children">
                <router-link
                  v-for="(its, i) in item.children"
                  :key="i"
                  tag="div"
                  :to="its.url"
                  class="dropdown-item"
                >
                  {{ its.name }}
                </router-link>
              </div>
              <!-- </a> -->
            </router-link>
          </ul>
        </div>
      </nav>
      <!--  <div
        class="page_nav_container row align-items-center justify-content-between d-flex"
      >
        <div id="logo" class="col-lg-4 col-md-12">
          <a href="index.html">
            <img
              :src="logo"
              alt="logo加载失败"
              title="中国（漯河）食品博览会"
            />
          </a>
        </div>
        <div class="but_m">
          <button type="button" id="mobile-nav-toggle"><i class="lnr lnr-menu"></i></button>
        </div>
        <div class="left col-lg-8 col-md-12">
          <div class="time">距展会开幕还有120天</div>
          <ul class="nav-menu sf-js-enabled sf-arrows">
            <router-link
              v-for="(item, index) in navData"
              :key="index"
              tag="li"
              :to="item.url"
              class="nav-item menu-active"
            >
              {{ item.name }}
              <div class="menu_list">
                <router-link
                  v-for="(its, i) in item.children"
                  :key="i"
                  tag="div"
                  :to="its.url"
                >
                  {{ its.name }}
                </router-link>
              </div>
            </router-link>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getTimLimit } from "@/api/index.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      showFlage: false,
      showFlage1: true,
      m_nav_show: false,
      activeIndex: 0,
      djs: require("@/assets/img/home/djs.png"),
      navData: [
        {
          name: "首页",
          url: "/index",
        },
        {
          name: "展会概况",
          url: "",
          children: [
            {
              name: "关于展会",
              url: "/aboutEx",
            },
            {
              name: "会议活动",
              url: "/plan",
            },
            {
              name: "历届回顾",
              url: "/previousExhibitions",
            },
            {
              name: "组织机构",
              url: "/org",
            },
          ],
        },
        {
          name: "主题展区",
          url: "/displayArea",
        },
        {
          name: "参展服务",
          url: "",
          children: [
            {
              name: "参展对象",
              url: "/exRules",
            },
            {
              name: "参展报名",
              url: "/exSignUp",
            },
            {
              name: "展位示意图",
              url: "/boothLayout",
            },
            {
              name: "广告招商报名",
              url: "/advSignUp",
            },
          ],
        },
        {
          name: "观众服务",
          url: "",
          children: [
            {
              name: "展商名录",
              url: "/exhibitor",
            },
            {
              name: "采购商报名",
              url: "/signUp",
            },
          ],
        },
        {
          name: "新闻中心",
          url: "/news",
        },
        {
          name: "漯河风采",
          url: "/introduce",
        },
        {
          name: "联系我们",
          url: "/contactUs",
        },
      ],
      logo: require("@/assets/img/logo.png"),
      TimLimit: "0",
      Msg: "登录",
      userInfo: {},
      url: "",
    };
  },
  /*c omputed: {
    ...mapState({ userInfo: (state) => state.user_module.user }),
  }, */
  methods: {
    changeNav() {
      this.showFlage = !this.showFlage;
    },
    // 开幕倒计时
    getTime() {
      getTimLimit().then((res) => {
        this.TimLimit = res.result;
      });
    },
    openWeb(val) {
      this.$router.push(val);
    },
  },
  created() {
    this.getTime();
    this.userInfo = JSON.parse(window.sessionStorage.getItem("user"));
    /* if (this.userInfo && this.userInfo.userType) {
      this.url = this.userInfo.userType == 1 ? "/exhibitors_center" : "/buyers";
    } */
  },
  watch: {
    $route: {
      handler(v) {
        // console.log("菜单：", v);
        this.navData.forEach((item, index) => {
          if (item.children) {
            item.children.forEach((it, i) => {
              if (it.url == v.path) {
                this.activeIndex = index;
              }
            });
          } else {
            if (item.url == v.path) {
              this.activeIndex = index;
            }
          }
        });
        if (v == 1 || v == 3 || v == 4) {
          this.showFlage = true;
        } else {
          this.showFlage = false;
        }
        // console.log(this.showFlage);
        // console.log(this.activeIndex);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="less">
.login {
  position: relative;
  z-index: 999;
  div {
    position: absolute;
    right: 220px;
    top: 15px;
    cursor: pointer;
    i {
      font-size: 25px;
      vertical-align: middle;
      color: #ff6600;
    }
    span {
      font-size: 16px;
      vertical-align: middle;
      margin-left: 6px;
      color: #ff6600;
    }
  }
}
li.router-link-exact-active {
  a {
    color: #3f18ec;
  }
}
.navbar-light .navbar-nav .nav-link.active {
  color: #bb233f;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #bb233f;
}
.page_nav {
  background-color: #fff;
  line-height: 50px;
  text-align: center;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.14);
  .navbar {
    padding: 0.5rem 0;
  }
  .navbar-toggler {
    padding: 0 4px;
    margin-top: 13px;
  }
  #navbarTogglerDemo03.active {
    display: none;
  }
  #navbarTogglerDemo02 {
    margin-top: 40px;

    ul.navbar-nav {
      justify-content: space-around;
      width: 100%;
      li {
        padding: 0 0px;
        font-size: 16px;
        color: #10141f;
        cursor: pointer;
        line-height: 20px;
        &:hover {
          .dropdown-menu {
            display: block;
          }
        }
        &:hover,
        &.active {
          color: #bb233f;
        }
        .dropdown-menu {
          top: 80%;
          div {
            &:hover {
              color: #bb233f;
            }
          }
        }
        i {
          font-size: 14px;
        }
      }
    }
  }
  .time {
    text-align: right;
    position: absolute;
    right: 25px;
    top: 10px;
    &.time1 {
      top: 27px;
      line-height: 30px;
    }
    span {
      width: 38px;
      height: 41px;
      background-size: 100%;
      display: inline-block;
      text-align: center;
      font-size: 23px;
      font-weight: bold;
      color: #aa120f;
      padding-top: 3px;
    }
  }
  .navbar-brand {
    text-align: left;
    padding: 20px 0;
    img {
      max-width: 333px;
    }
  }
  .bg-light {
    background: rgba(255, 255, 255, 0.9) !important;
  }
  .nav-item {
    position: relative;
    a.nav-link {
      &:hover {
        .dropdown-menu {
          display: block;
        }
      }
    }
  }
  .dropdown-menu {
    min-width: 6rem;
  }
  .page_nav_container {
    max-width: 1400px;
    width: 100%;
    // min-width: 990px;
    margin: 0 auto;
    .left {
      display: block;
    }
    ul {
      display: flex;
      justify-content: space-around;
      li {
        font-size: 16px;
        color: #10141f;
        display: inline-block;
        cursor: pointer;
        position: relative;
        width: calc(100% / 9);
        &:hover {
          background-color: rgba(0, 0, 0, 0.5);
          color: #bb233f;
          .menu_list {
            display: block;
            // transition: all 1s;
          }
        }
        .menu_list {
          position: absolute;
          width: 100%;
          left: 0;
          top: 50px;
          display: none;
          background: rgba(0, 0, 0, 0.5);
          z-index: 2000;
          div {
            font-size: 14px;
            padding: 5px 10px;
            color: #fff;
            line-height: 32px;
            border-top: 1px solid rgba(0, 0, 0, 0.5);

            &:hover {
              //   background: #960d05;
              color: #bb233f;
            }
          }
        }
      }
    }
  }
}
.dropdown-item:active {
  background-color: #fff;
  color: #aa120f;
}
.dropdown-item {
  padding: 1rem;
}
@media (max-width: 750px) {
  .page_nav .navbar-brand {
    img {
      max-width: 233px !important;
    }
  }
  #navbarTogglerDemo02 {
    margin-top: 0 !important;
    ul.navbar-nav {
      li {
        padding: 10px 0 !important;
      }
    }
  }
  .page_nav {
    .time {
      display: none;
      color: #212121;
    }
  }
  .login {
    div {
      top: 0px;
      right: 0px;
      span {
        font-size: 14px;
        color: #ff6600;
      }
      i {
        font-size: 20px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1400px;
  }
}
</style>