import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/* 
在vue中引入jquery
import $ from 'jquery';
window.jQuery = $;
window.$ = $; */

// import '@/assets/js/font-size.js'
// import '@/assets/css/reset.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '@/assets/css/main.css';
import VideoPlayer from 'vue-video-player'

import VDistpicker from 'v-distpicker'
Vue.component('v-distpicker', VDistpicker)

Vue.config.productionTip = false

Vue.use(VideoPlayer)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
