<template>
  <div class="page">
    <div class="container">
      <div class="page_con row">
        <div class="tab_left col-lg-4 col-2">
          <div class="image" v-if="form.logo">
            <img :src="form.logo" alt="" />
          </div>
          <p class="name">{{ form.name }}</p>
          <div
            class="tab"
            :class="activeIndex == 0 ? 'active' : ''"
            @click="chengeTab(0)"
          >
            <i class="iconfont iconxinxi"> </i>
            <span> 展位信息 </span>
          </div>
          <div
            class="tab"
            :class="activeIndex == 1 ? 'active' : ''"
            @click="chengeTab(1)"
          >
            <i class="iconfont icon3Dzhanshi"> </i>
            <span> 展品信息 </span>
          </div>
          <div
            class="tab"
            :class="activeIndex == 2 ? 'active' : ''"
            @click="chengeTab(2)"
          >
            <i class="iconfont iconlianxiren"> </i>
            <span> 人员管理 </span>
          </div>
        </div>
        <div class="tab_right col-lg-8 col-10">
          <p class="name">{{ form.name }}</p>
          <section class="section1" v-show="activeIndex == 0">
            <div class="title">
              <i></i>
              <span>展位信息</span>
              <em>展位信息将显示在商家页面。</em>
            </div>
            <!-- 表单 -->
            <form style="margin-top: 20px">
              <div class="form-group row">
                <label class="col-sm-2 col-4 col-form-label"
                  >展商名称 <i>*</i></label
                >
                <div class="col-sm-6 col-8">
                  <input class="form-control" v-model="form.name" required />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-4 col-form-label"
                  >展区面积 <i>*</i></label
                >
                <div class="col-sm-6 col-8">
                  <input
                    class="form-control"
                    v-model="form.appleArea"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-4 col-form-label"
                  >选择展区 <i>*</i></label
                >
                <div class="col-sm-6 col-8">
                  <select
                    :value="form.exhibitorsArea"
                    v-model="form.exhibitorsArea"
                    @change="changeArea()"
                    style="width: 100%; height: 35px; border-color: #ced4da"
                  >
                    <option
                      v-for="item in info"
                      :value="item.name"
                      :key="item.name"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-4 col-form-label"
                  >展品 <i>*</i></label
                >
                <div class="col-sm-6 col-8">
                  <input
                    class="form-control"
                    v-model="form.appleExhibit"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-4 col-form-label"
                  >联系人 <i>*</i></label
                >
                <div class="col-sm-6 col-8">
                  <input class="form-control" v-model="form.contact" required />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-4 col-form-label"
                  >联系方式 <i>*</i></label
                >
                <div class="col-sm-6 col-8 getPhone">
                  <input
                    class="form-control"
                    v-model="form.contactPhone"
                    required
                  />
                  <!-- <span
                    class="check_phone"
                    :class="check_phone ? 'active' : ''"
                    >{{ check_msg_phone }}</span
                  > -->
                </div>
              </div>
              <!-- 上传 -->
              <div class="form-group row">
                <label class="col-sm-2 col-4 col-form-label"
                  >logo<i>*</i></label
                >
                <div class="col-sm-6 col-8">
                  <input class="fileUrl" v-model="mapItem.MapIcon" />
                  <input
                    class="upFile"
                    accept="image/*"
                    type="file"
                    id="tempUploadFile"
                    v-on:change="uploadPic($event)"
                  />
                  <!-- <img class="imgbgbox" v-bind:src="mapItem.MapIcon" /> -->
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-4 col-form-label"
                  >公司简介<i>*</i></label
                >
                <div class="col-sm-6 col-8">
                  <textarea
                    rows="6"
                    style="width: 100%"
                    v-model="form.introduction"
                  >
                  </textarea>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-4 col-form-label">备注</label>
                <div class="col-sm-6 col-8">
                  <textarea
                    rows="3"
                    style="width: 100%"
                    v-model="form.comments"
                  >
                  </textarea>
                </div>
              </div>
              <div class="but">
                <p v-html="error_msg"></p>
                <span @click="submit()" class="btn btn-primary">提交</span>
              </div>
            </form>
          </section>
          <!-- 展品 -->
          <section class="section2" v-show="activeIndex == 1">
            <div class="title">
              <i></i>
              <span>展品信息</span>
              <em>共{{ total }}个展品</em>
              <div class="but add_but">
                <span @click="add1Pro()" class="pc_add">+ 添加展品</span>
                <span @click="add1_M_Pro()" class="m_add">+ 添加展品</span>
              </div>
            </div>
            <ul class="row pro_list">
              <li
                class="col-lg-3 col-6"
                v-for="(item, index) in proList"
                :key="index"
              >
                <div class="list_con">
                  <div
                    class="image"
                    @click="openWeb('/proDetail?id=' + item.id)"
                    :style="{ backgroundImage: `url(${item.path[0]})` }"
                  >
                    <!-- <img :src="item.path[0]" alt="" /> -->
                  </div>
                  <p class="title">
                    {{ item.name }}
                  </p>
                </div>
                <div class="but">
                  <span @click="edit(item)">编辑</span>
                  <span @click="delet(item.id)">删除</span>
                </div>
              </li>
            </ul>
          </section>
          <!-- 人员管理 -->
          <section class="section3" v-show="activeIndex == 2">
            <div class="title">
              <i></i>
              <span>人员管理</span>
              <!-- <em>共{{ total }}个展品</em>-->
              <div class="but add_but">
                <span @click="add1Person()" class="pc_add">+ 添加人员</span>
                <span @click="add1_M_Person()" class="m_add">+ 添加人员</span>
              </div>
            </div>
            <ul class="row people_list">
              <li
                class="col-12"
                v-for="(item, index) in peopleList"
                :key="index"
              >
                <div class="list_con">
                  <p class="title">
                    {{ item.name }}
                  </p>
                  <p>
                    {{ item.mobile }}
                  </p>
                  <p>
                    {{ item.idCard }}
                  </p>
                  <p class="but">
                    <span @click="deletPeople(item.id)">删除</span>
                  </p>
                </div>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
    <!-- <button
      type="button"
      class="btn btn-primary"
      data-toggle="modal"
      data-target="#staticBackdrop"
    >
      Launch static backdrop modal
    </button> -->
    <div
      class="modal"
      :class="modal ? 'active' : ' fade'"
      id="staticBackdrop"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ modalTitle }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" @click="modal = false">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="delet_item" v-if="modalType == 1">
              <p>删除后将不能恢复</p>
              <p>确定要删除该展品？</p>
            </div>
            <div class="delet_item" v-if="modalType == 10">
              <p>添加和编辑功能，请移步pc端操作</p>
            </div>
            <div class="delet_item" v-if="modalType == 5">
              <p>删除后将不能恢复</p>
              <p>确定要删除该人员？</p>
            </div>
            <!-- 添加和编辑展品 -->
            <div class="edit_item" v-if="modalType == 2 || modalType == 3">
              <!-- 表单 -->
              <form style="margin-top: 20px">
                <div class="form-group row">
                  <label class="col-sm-2 col-4 col-form-label"
                    >展品名称 <i>*</i></label
                  >
                  <div class="col-sm-9 col-8">
                    <input
                      class="form-control"
                      v-model="formPro.name"
                      required
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 col-4 col-form-label"
                    >展品类型 <i>*</i></label
                  >
                  <div class="col-sm-6 col-8">
                    <select
                      :value="formPro.productType"
                      v-model="formPro.productType"
                    >
                      <option
                        v-for="item in info"
                        :value="item.name"
                        :key="item.name"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- 上传 -->
                <div class="form-group row" style="text-align: left">
                  <label class="col-sm-2 col-4 col-form-label"
                    >展品图片<i>*</i></label
                  >
                  <div class="col-sm-9 col-8">
                    <input
                      class="fileUrl"
                      v-model="formPro.path"
                      readonly="readonly"
                    />
                    <input
                      class="upFile"
                      accept="image/*"
                      type="file"
                      id="tempUploadFile"
                      v-on:change="uploadPic($event)"
                    />
                    <p
                      style="font-size: 14px; color: #999999; margin-bottom: 0"
                    >
                      请选择.jpg .png图片文件，且尺寸为
                      500×500像素的图片，大小不要超过1M。
                    </p>
                    <!-- <div
                      class="image"
                      :style="{ backgroundImage: `url(${up_back})` }"
                    >
                      <img class="imgbgbox" v-bind:src="mapItem.MapIcon" />
                    </div> -->
                  </div>
                </div>
                <div
                  class="line"
                  style="
                    border: 1px solid #eee;
                    width: 100%;
                    margin-bottom: 10px;
                  "
                  v-if="data.src"
                ></div>
                <div
                  class="form-group row"
                  style="text-align: left; padding-bottom: 50px"
                  v-if="data.src"
                >
                  <label class="col-sm-2 col-4 col-form-label"
                    >图片裁切<i>*</i>
                  </label>
                  <div class="col-sm-9 col-8">
                    <div class="rc-cropper">
                      <canvas :id="data.src" ref="canvas"></canvas>
                    </div>
                  </div>
                  <!-- </div>
                <div class="form-group row" style="text-align: left"> -->
                  <div class="rc-cropper__iconCrop1">
                    <span @click="initCropper()">裁剪</span>
                    <span @click="sureCropper()">确认裁剪</span>
                    <span @click="cancelCropper()">取消裁剪</span>
                  </div>
                </div>
              </form>
            </div>
            <!-- 添加人员 -->
            <div class="edit_item" v-if="modalType == 4">
              <!-- 表单 -->
              <form style="margin-top: 20px">
                <div class="form-group row">
                  <label class="col-sm-2 col-4 col-form-label"
                    >人员名称 <i>*</i></label
                  >
                  <div class="col-sm-9 col-8">
                    <input
                      class="form-control"
                      v-model="formPople.name"
                      required
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 col-4 col-form-label"
                    >身份证号 <i>*</i></label
                  >
                  <div class="col-sm-9 col-8">
                    <input
                      class="form-control"
                      v-model="formPople.idCard"
                      required
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 col-4 col-form-label"
                    >手机号 <i>*</i></label
                  >
                  <div class="col-sm-9 col-8">
                    <input
                      class="form-control"
                      v-model="formPople.mobile"
                      required
                    />
                  </div>
                </div>
                <p style="color: red">{{ promptMsg }}</p>
              </form>
            </div>
          </div>
          <div class="error_Msg">
            <p>{{ Msg }}</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="modal = false"
            >
              取消
            </button>
            <button
              type="button"
              v-if="modalType != 10"
              class="btn btn-primary"
              @click="submitPro()"
            >
              确定
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show" :class="modal ? 'active' : ''"></div>
  </div>
</template>

<script>
import {
  putExhibitors,
  deletePro,
  putPro,
  addPro,
  getExhibitorDetails,
  addExhibitorsUser,
  getExhibitorsUser,
  deleteExhibitorsUser,
} from "@/api/index.js";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import axios from "axios";
export default {
  data() {
    return {
      total: "0",
      modalTitle: "",
      modalType: 1,
      modal: false,
      mapItem: {
        MapIcon: "",
      },
      areaVal: "",
      areaFlage: false,
      check_msg_phone: "手机号格式不正确",
      check_phone: false,
      activeIndex: 0,
      up_back: require("@/assets/img/up_back.jpg"),
      logo: require("@/assets/img/home/logo.png"),
      code: require("@/assets/img/signUp/code.jpg"),
      code_back: require("@/assets/img/signUp/code_back.jpg"),
      form: {},
      info: [
        {
          name: "河南省特色食品",
          check: false,
        },
        {
          name: "境外食品",
          check: false,
        },
        {
          name: "肉制品及冻品",
          check: false,
        },
        {
          name: "粮油及调味品",
          check: false,
        },
        {
          name: "休闲食品",
          check: false,
        },
        {
          name: "酒水饮料",
          check: false,
        },
        {
          name: "烘焙食品",
          check: false,
        },
        {
          name: "食品加工机械",
          check: false,
        },
        {
          name: "综合展区",
          check: false,
        },
      ],
      proList: [],
      model2: "综合展区",
      baseUrl: process.env.VUE_APP_BASE_API,
      error_msg: "",
      formPro: {},
      formPople: {},
      cropper: null,
      croppShow: false,
      data: {
        src: "",
        type: "",
      },
      file: "",
      promptMsg: "", //添加人员时的错误提示
      exhibitorsId: "", //企业id
      peopleList: [],
      Msg: "", //添加产品时的错误提示
    };
  },
  methods: {
    openWeb(v) {
      this.$router.push(v);
    },
    // 在canvas上绘制图片
    drawImg(href) {
      this.$nextTick(() => {
        let canvas = document.getElementById(this.data.src);
        if (canvas) {
          canvas.width = 300;
          canvas.height = 300;
          let ctx = canvas.getContext("2d");
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          /* 
          绘制 对角线
          ctx.moveTo(0, 0);
          ctx.lineTo(300, 300);
          ctx.stroke(); */
          let img = new Image();
          img.crossOrigin = "Anonymous";
          img.src = href || this.data.src;
          img.onload = function () {
            var iw = this.width;
            var ih = this.height;
            if (iw > ih) {
              //得到图片的高宽比，用以计算canvas的高
              var scale = ih / iw;
              var cheight = 300 * scale;
              ctx.drawImage(img, 0, 0, canvas.width, cheight);
            } else {
              var scale = iw / ih;
              var cheight = 300 * scale;
              ctx.drawImage(img, 0, 0, cheight, canvas.height);
            }
            // ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          };
        }
      });
    },
    // 显示裁剪框
    initCropper() {
      this.drawImg();
      this.croppShow = true;
      let cropper = new Cropper(this.$refs.canvas, {
        checkCrossOrigin: true,
        viewMode: 2,
        aspectRatio: 3 / 3,
      });
      this.cropper = cropper;
    },
    // 确认裁剪
    sureCropper(e) {
      let _this = this;
      if (this.cropper) {
        this.cropper.getCroppedCanvas().toBlob(function (blob) {
          let name = _this.file.name;
          if (_this.modalType == 2) {
            name = _this.data.type;
          }
          const href = window.URL.createObjectURL(blob);
          var formData = new FormData();
          formData.append("file", blob, name);
          axios({
            url: _this.baseUrl + "/upload/file",
            method: "post",
            data: formData,
          }).then((r) => {
            if (r.success) {
              _this.mapItem.MapIcon = r.result;
              _this.data.src = r.result;
              _this.formPro.path = r.result;
              _this.Msg = "";
              _this.drawImg(_this.data.src);
              _this.cancelCropper();
            }
          });
        }, "image/jpeg");
      }
    },
    // 销毁裁剪框
    cancelCropper() {
      if (this.cropper) {
        this.cropper.destroy();
        this.croppShow = false;
        this.cropper = null;
      }
    },
    add1_M_Person() {
      this.modalTitle = "提示";
      this.modalType = 10;
      this.modal = true;
    },
    add1Person() {
      this.modalTitle = "添加人员";
      this.modalType = 4;
      this.modal = true;
      this.formPople = {};
    },
    deletPeople(v) {
      this.modalTitle = "确认删除";
      this.modalType = 5;
      this.modal = true;
      this.id = v;
    },

    add1_M_Pro() {
      this.modalTitle = "提示";
      this.modalType = 10;
      this.modal = true;
    },
    add1Pro() {
      this.modalTitle = "添加展品";
      this.modalType = 3;
      this.mapItem.MapIcon = "";
      this.formPro.name = "";
      this.formPro.productType = "";
      this.formPro.path == "";
      this.data.src = "";
      this.modal = true;
      this.$nextTick(() => {
        this.drawImg();
      });
    },
    // 编辑
    edit(v) {
      // console.log(v);
      this.modalTitle = "编辑展品";
      this.modalType = 2;
      for (let attr in v) {
        if (v[attr] == null) {
          v[attr] = "";
        }
      }
      let str = JSON.stringify(v);
      let data = JSON.parse(str);
      this.formPro = data;
      this.mapItem.MapIcon = data.path;
      this.data.src = data.path;
      this.data.type =
        new Date().getTime() + data.path.substr(data.path.lastIndexOf("."));
      this.modal = true;
      this.$nextTick(() => {
        this.drawImg();
      });
    },
    submitPro() {
      if (this.modalType == 1) {
        // 删除产品
        deletePro({ ids: this.id }).then((res) => {
          if (res.success) {
            this.modal = false;
            this.getData();
          }
        });
      } else if (this.modalType == 2) {
        if (this.formPro.name == "") {
          this.Msg = "展品名称不能为空";
          return;
        } else if (this.formPro.productType == "") {
          this.Msg = "展品类型不能为空";
          return;
        }
        // 编辑产品
        let cur = {
          name: this.formPro.name,
          id: this.formPro.id,
          productType: this.formPro.productType,
        };
        cur.path = this.mapItem.MapIcon;
        putPro(cur).then((res) => {
          if (res.success) {
            this.modal = false;
            this.getData();
            this.Msg = "";
          }
        });
      } else if (this.modalType == 3) {
        // 添加产品
        if (this.formPro.name == "") {
          this.Msg = "展品名称不能为空";
          return;
        } else if (this.formPro.productType == "") {
          this.Msg = "展品类型不能为空";
          return;
        } else if (
          this.data.src == "" ||
          this.formPro.path == "" ||
          !this.formPro.path
        ) {
          this.Msg = "请上传并裁剪展品图片";
          return;
        } else {
          /*  else if (this.formPro.path == "") {
          this.Msg = "展品图片不能为空";
          return;
        } */
          let cur = {
            name: this.formPro.name,
            productType: this.formPro.productType,
            path: this.formPro.path,
          };
          // console.log(cur, this.data.src, this.formPro.path);
          // cur.path = this.mapItem.MapIcon;
          addPro(cur).then((res) => {
            if (res.success) {
              this.modal = false;
              this.getData();
              this.Msg = "";
            }
          });
        }
      } else if (this.modalType == 4) {
        // 添加人员
        if (this.formPople.name == "") {
          this.promptMsg = "人员名称不能为空";
        } else if (this.formPople.idCard == "") {
          this.promptMsg = "身份证号不能为空";
        } else if (this.formPople.mobile == "") {
          this.promptMsg = "手机号不能为空";
        } else {
          /* var reg = /^[1-9]d{5}(18|19|([23]d))d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)d{3}[0-9Xx]$/;
          if (reg.test(this.formPople.idCard) == false) {
            this.promptMsg = "身份证号格式不正确";
          } else {
            this.promptMsg = "";
          } */
          this.promptMsg = "";
          this.formPople.exhibitorsId = this.exhibitorsId;
          addExhibitorsUser(this.formPople).then((res) => {
            if (res.success) {
              this.modal = false;
              this.getPeopleData();
            }
          });
        }
      } else if (this.modalType == 5) {
        // 删除产品
        deleteExhibitorsUser({ ids: this.id }).then((res) => {
          if (res.success) {
            this.modal = false;
            this.getPeopleData();
          }
        });
      }
    },
    // 删除
    delet(v) {
      this.modalTitle = "确认删除";
      this.modalType = 1;
      this.modal = true;
      this.id = v;
    },
    // 选择展区
    changeArea() {
      // console.log(this.model2);
    },
    // 上传图片
    uploadPic(e) {
      var _self = this;
      var inputFile = e.target;
      if (!inputFile.files || inputFile.files.length <= 0) {
        return;
      }
      this.file = inputFile.files[0];
      // console.log(this.file);
      var formData = new FormData();
      formData.append("file", this.file);
      //$.whiskey.tools.dateFormat(new Date(), "HHmmssffff")
      axios({
        url: this.baseUrl + "/upload/file",
        method: "post",
        data: formData,
        // headers: {
        //   "Content-Type": "application/json;",
        // },
      }).then((r) => {
        if (r.success) {
          // this.modal = true;
          this.mapItem.MapIcon = r.result;
          this.data.src = r.result;
          this.drawImg(this.data.src);
        }
      });
    },
    // 修改展商信息
    submit() {
      for (var key in this.form) {
        if (this.form[key] == "" || this.form[key] == null) {
          delete this.form[key];
        }
      }
      this.form.logo = this.mapItem.MapIcon;
      putExhibitors(this.form).then((res) => {
        if (res.success) {
          this.error_msg = "<span style='color:green;'>修改成功</span>";
          setTimeout(() => {
            this.error_msg = "";
          }, 3000);
        } else {
          this.error_msg = `<span style='color:red;'>${res.message}</span>"`;
        }
      });
    },
    getPeopleData() {
      getExhibitorsUser({
        exhibitorsId: this.exhibitorsId,
        size: 100,
        delFlag: 1,
      }).then((res) => {
        if (res.success) {
          this.peopleList = res.result.records;
        }
      });
    },
    getData() {
      getExhibitorDetails().then((res) => {
        if (res.success) {
          this.form = res.result;
          this.exhibitorsId = this.form.id;
          this.mapItem.MapIcon = this.form.logo;
          res.result.products.forEach((item) => {
            item.path = JSON.parse(item.path);
          });
          this.proList = res.result.products;
          this.total = this.proList.length;
          this.getPeopleData();
        } else {
          this.form = {};
        }
      });
      /*  getProductByExhibitorsId({ id: 1, size: 100, delFlag: 1 }).then((r) => {
        if (r.success) {
          this.proList = r.result.records;
          this.total = r.result.total;
        } else {
          this.proList = [];
        }
      });
      getExhibitorsById(1).then((res) => {
        if (res.success) {
          this.form = res.result;
          this.mapItem.MapIcon = this.form.logo;
        } else {
          this.form = {};
        }
      }); */
    },
    chengeTab(v) {
      this.activeIndex = v;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped lang="less">
.page {
  background-color: #f9f9f9;
  padding: 20px 0;
  .error_Msg {
    text-align: right;
    padding: 0 20px;
    p {
      margin: 0;
      color: red;
      font-size: 14px;
    }
  }
  .modal-dialog {
    margin-top: 5vh;
    max-width: 700px;
    .image {
      width: 200px;
      text-align: left;
      margin: 20px 0;
      min-height: 200px;
      img {
        width: 100%;
      }
    }
  }
  .modal-backdrop {
    display: none;
    &.active {
      display: block;
    }
  }
  .modal {
    //   top: 10vh;
    &.active {
      display: block;
    }
  }
  .map {
    width: 100%;
    height: 400px;
    padding-top: 30px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px;
    overflow: hidden;
    background: #f0f0f0;
    margin-top: 20px;
    #GDMap {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      margin: 10px;
      width: 100%;
      display: inline-block;
      height: 350px;
      border: 4px solid #fff;
    }
  }
  .page_con {
    background-color: #fff;
    padding: 30px 20px;
    min-height: 70vh;
    .tab_left {
      background-color: #fafafa;
      padding: 10px 0;
      div.image {
        max-height: 250px;
        overflow: hidden;
        img {
          margin: 20px 0 0 0;
        }
      }
      p.name {
        font-size: 14px;
        color: #595959;
        padding: 10px 0;
      }
      div.tab {
        padding: 10px 0;
        text-align: center;
        cursor: pointer;
        &:hover,
        &.active {
          background-color: #eeeeee;
        }
        &:nth-child(2) {
          i {
            color: #5883f0;
          }
        }
        &:nth-child(3) {
          i {
            color: #ff8b29;
          }
        }
        &:nth-child(4) {
          i {
            color: #2bc177;
          }
        }
        i {
          font-size: 20px;
          vertical-align: middle;
        }
        span {
          font-size: 16px;
          color: #595959;
          vertical-align: middle;
        }
      }
    }
    .tab_right {
      padding: 0 20px;
      text-align: left;
      p.name {
        display: none;
        font-size: 14px;
        color: #595959;
        padding: 10px 0;
      }
      .title {
        i {
          display: inline-block;
          vertical-align: middle;
          border: 2px solid #ff8b29;
          height: 18px;
          margin-right: 6px;
        }
        span {
          font-size: 16px;
          color: #595959;
          vertical-align: middle;
        }
        em {
          font-size: 14px;
          color: #999999;
          font-style: normal;
          margin-left: 10px;
        }
        .but {
          display: inline-block;
          vertical-align: middle;
          margin-left: 20px;
          span {
            background: #ff6600;
            border-radius: 4px;
            display: inline-block;
            padding: 2px 10px;
            font-size: 14px;
            color: #ffffff;
            cursor: pointer;
            &.m_add {
              display: none;
            }
          }
        }
      }
      p {
        font-size: 14px;
        color: #313131;
        padding: 10px;
      }
    }
    .code-body {
      background: #ffffff;
      box-shadow: 0px 0px 5px 0px rgba(39, 39, 39, 0.16);
      border-radius: 4px;
      margin-top: 20px;
      padding: 10px;
      text-align: center;
      .modal-title {
        font-size: 12px;
        font-weight: 400;
        color: #000000;
      }
      .box {
        padding: 20px 10px;
        h4 {
          color: #fff;
          padding: 0px 0 20px 0;
        }
        .code {
          background-size: cover;
          padding: 20px 0px;
          img {
            width: 100%;
            padding: 0 20px;
          }
          p {
            color: #fff;
            font-size: 14px;
            margin: 10px 0 0 0;
          }
        }
      }
    }
  }
  .but {
    text-align: center;
    .btn-primary {
      background: #ff6600;
      border-color: #ff6600;
      width: 40%;
    }
  }
  .col-form-label {
    text-align: right;
    font-size: 14px;
    i {
      color: red;
    }
  }
  .fileUrl,
  .upFile {
    display: inline-block;
  }
  .upFile {
    width: 80px;
    margin-left: 10px;
  }
  .pro_list {
    margin-top: 20px;
    li {
      margin-top: 10px;
      cursor: pointer;
      .image {
        min-height: 150px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid #eee;
      }
      img {
        width: 100%;
      }
      .but {
        span {
          font-size: 14px;
          color: #212121;
          display: inline-block;
          padding: 0px 10px;
          cursor: pointer;
          border-radius: 4px;
          &:nth-child(1) {
            background: #ddedff;
            border: 1px solid #73acec;
          }
          &:nth-child(2) {
            background: #ffd6d6;
            border: 1px solid #ef8e8e;
            margin-left: 10px;
          }
        }
      }
      p{
        margin: 0;
      }
    }
  }
  .people_list {
    margin-top: 20px;
    li {
      .list_con {
        box-shadow: 0px 0px 6px 0px rgba(39, 39, 39, 0.16);
        background: linear-gradient(0deg, #f5f5f5, #ffffff);
        border-radius: 4px;
        border: 1px solid #eeeeee;
        padding: 10px;
        margin-top: 6px;
        p {
          display: inline-block;
          margin: 0;
        }
        .but {
          span {
            font-size: 14px;
            color: #212121;
            display: inline-block;
            padding: 0px 10px;
            cursor: pointer;
            border-radius: 4px;
            background: #ddedff;
            border: 1px solid #73acec;
          }
        }
      }
    }
  }
  .delet_item {
    margin-top: 30px;
  }
  .rc-cropper {
    position: relative;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .rc-cropper__iconCrop1 {
    position: absolute;
    right: 10%;
    bottom: 2%;
    span {
      font-size: 14px;
      padding: 2px 10px;
      border: 1px solid #eee;
      margin-top: 10px;
      display: inline-block;
      cursor: pointer;
      margin-left: 10px;
      border-radius: 6px;
      &:hover {
        color: royalblue;
        border: 1px solid royalblue;
      }
    }
    div.but {
    }
  }
  .el-button {
    margin: 20px 0;
    display: block;
    z-index: 10000;
  }
}
@media (min-width: 750px) {
  .people_list {
    li {
      .list_con {
        display: flex;
        justify-content: space-around;
      }
    }
  }
}
@media (max-width: 750px) {
  .page .page_con .tab_left div.tab span {
    display: none;
  }
  .page .page_con .tab_right {
    padding-right: 0px;
    padding-left: 10px;
  }

  .page .page_con .tab_left {
    p.name {
      display: none;
    }
    img {
      display: none;
    }
  }
  .page .page_con .tab_right p.name {
    display: block;
  }
  .pro_list {
    .but {
      display: none;
    }
  }
  .page {
    .section2 {
      .add_but {
        .m_add {
          display: block !important;
        }
        .pc_add {
          display: none !important;
        }
      }
    }
    .section3 {
      .add_but {
        .m_add {
          display: block !important;
        }
        .pc_add {
          display: none !important;
        }
      }
      .people_list {
        p.but {
          display: none !important;
        }
      }
    }

    .upFile {
      display: none !important;
    }
  }
}
</style>