<template>
  <div class="page">
    <div class="banner">
      <img :src="banner" alt="" />
    </div>
    <section class="city">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 item">
            <img :src="static1" alt="" />
          </div>
          <div class="col-lg-7 col-12">
            <div class="title">城市概况</div>
            <p style="text-indent: 2em;">
              漯河市位于河南省中南部，伏牛山东麓平原与淮北平原交错地带，属暖湿性季风气候，四季分明，农业基础条件较好。淮河两大支流沙河、澧河贯穿全境并在市区交汇,滨河城市特色明显。漯河1948年设立县级市，1986年升格为省辖市，现辖临颍、舞阳两县和郾城、源汇、召陵三区及国家级经济技术开发区、市城乡一体化示范区、西城区三个功能区。
            </p>
            <ul>
              <li>
                <p>2617平方公里</p>
                <p>总面积</p>
              </li>
              <li>
                <p>283万人</p>
                <p>总人口</p>
              </li>
              <li>
                <p>72平方公里</p>
                <p>城区面积</p>
              </li>
              <li>
                <p>72万人</p>
                <p>城区人口</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="section_item2">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12 item">
            <img :src="static4" alt="" />
          </div>
          <div class="col-lg-4 col-12 item">
            <img :src="static3" alt="" />
          </div>
          <div class="col-lg-4 col-12 item">
            <img :src="static2" alt="" />
          </div>
          <p>
            漯河是一个历史悠久、文化灿烂的古老城市。早在新石器时代，先民就在这里定居生息。贾湖遗址发现的裴李岗文化刻画符号距今已有8000年之遥，将中国文字史向前推进了4000年；出土的国宝七音骨笛，将中国音乐文化史向前推进了3000年；考古发掘的酿酒工艺，将中国造酒史向前推进了3000多年。东汉时期著名的经学家和文字学家许慎诞生于此，他编纂的《说文解字》是中国乃至世界最早的字典，被誉为“字学宗师”。全市现有各类文物景点200余处，其中全国重点文物保护单位7处8项，省级重点文物保护单位36处38项，市级文物保护单位27处。
          </p>
        </div>
      </div>
    </section>
    <section class="section_item3">
      <img :src="banner1" alt="" />
    </section>
    <section class="section_item4">
      <div class="container">
        <div class="section_con">
          <div class="row item4_con">
            <div class="col-lg-6 col-12 image left">
              <img :src="static5" alt="" />
            </div>
            <div class="col-lg-6 col-12 text right">
              <div>
                <p>漯河是一个区位优越、交通发达的枢纽城市。</p>
                <p>
                  曾以商贸和水陆交通发达而享誉中原，在明朝永乐年间已是“江淮百货萃，此处星辰罗”的商品集散中心，素有“水旱码头”之称。今天的漯河，更是四通八达，是国家二类交通枢纽城市。距郑州新郑国际机场不足一小时车程，石武高铁、京广、漯宝（丰）、漯阜（阳）4条铁路和京港澳高速、宁洛高速、107国道及5条省道贯穿全境，构成全省重要的铁路和高速公路“双十字”交通枢纽。“三通一达”、顺丰、德邦、邮政等企业在漯河建立区域分拔中心，国家A级企业达21家，6家企业进入中国冷链物流百强。
                </p>
              </div>
            </div>
          </div>
          <div class="row item4_con">
            <div class="col-lg-6 col-12 text left">
              <div>
                <p>漯河是一个特色鲜明、享誉四方的食品名城。</p>
                <p>
                  漯河食品加工主导产业特色明显，培育出了全球最大的猪肉加工企业双汇集团、驰名中外的南街村集团、亚洲最大的绿色天然色素供应商中大生物、中国辣味休闲食品第一品牌卫龙食品等一批知名食品企业。食品产业主营业务收入已达1800亿元，占河南省的1/6、全国的1/60，火腿肠、冷鲜肉、麻辣面制品、休闲食品产销量均居全国第一位。如今，漯河的食品产业已形成了原料种养、食品研发、食品加工、食品包装、食品物流、食品会展、食品专业人才培养等一二三产融合发展的完整产业链，正在围绕“1+8+N”培育和打造丰满的绿色食品产业生态。
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-12 image right">
              <img :src="static6" alt="" />
            </div>
          </div>
          <div class="row item4_con">
            <div class="col-lg-6 col-12 image left">
              <img :src="static7" alt="" />
            </div>
            <div class="col-lg-6 col-12 text right">
              <div>
                <p>漯河是一个改革创新、充满活力的开放城市。</p>
                <p>
                  改革开放不断取得新进展，国有企业改革较早完成转机建制任务，非公有制经济发展迅速，占全市生产总值的比重达60%以上。现有20多个国家和地区的客商投资漯河，美国杜邦、美国高盛、可口可乐、日本火腿株式会社、中粮集团等15家世界500强企业，中盐、中农、恒安、双星、国美等国内500强企业18家以及香港华懋、协鑫、台湾旺旺、统一、宏全等一批境内外知名企业在漯河都有较大的投资项目，并获得了良好的投资回报。漯河是全国综合改革试点市、全国开拓农村市场试点市、国家农村信息化综合信息服务试点市、中部最佳投资城市。
                </p>
              </div>
            </div>
          </div>
          <div class="row item4_con">
            <div class="col-lg-6 col-12 text left">
              <div>
                <p>漯河是一个大局稳定、社会和谐的文明城市。</p>
                <p>
                  各项社会事业繁荣发展，精神文明建设和民主法制建设全面加强。计划生育、环境保护、土地管理三大国策得到较好落实。依法治市、基础教育、群众体育、残疾人康复、农村五保户集中供养等方面的工作走在全国前列。信访工作全省先进，安全生产形势总体平稳，公众安全感指数全省第一，社会大局和谐稳定。漯河是全国首批创建文明城市工作先进城市、全国普法依法治理先进城市、全国社会治安综合治理优秀市、全国信访工作先进集体、全省平安建设先进市、全省安全生产优秀市和全省双拥模范城。
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-12 image right">
              <img :src="static8" alt="" />
            </div>
          </div>
          <div class="row item4_con_other">
            <div class="col-12 text">
              <p>漯河是一个大局稳定、社会和谐的文明城市。</p>
              <p>
                2019年全市实现生产总值1578.4亿元，比上年增长7.5%，三次产业结构为8.7:46.3:45.0，规上工业增加值增长8.4%，固定资产投资增长11.4%，社会消费品零售总额642.8亿元，增长10.8%，财政一般公共预算收入95.9亿元，增长8.5%，税占比达76.4%。
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="list_section relative">
      <div class="container">
        <div class="list" v-if="data.length > 0">
          <ul class="row">
            <li class="col-lg-3 col-6"></li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getExhibitorsProduct } from "@/api/index.js";
export default {
  data() {
    return {
      banner: require("@/assets/img/introduce/banner.jpg"),
      banner1: require("@/assets/img/introduce/banner1.jpg"),
      static1: require("@/assets/img/introduce/static1.jpg"),
      static2: require("@/assets/img/introduce/static2.jpg"),
      static3: require("@/assets/img/introduce/static3.png"),
      static4: require("@/assets/img/introduce/static4.jpg"),
      static5: require("@/assets/img/introduce/static5.jpg"),
      static6: require("@/assets/img/introduce/static6.jpg"),
      static7: require("@/assets/img/introduce/static7.jpg"),
      static8: require("@/assets/img/introduce/static8.jpg"),
      activeIndex: 0,
      typeList: [
        {
          name: "综合展区",
        },
        {
          name: "河南省特色食品",
        },
        {
          name: "境外食品",
        },
        {
          name: "肉制品及冻品",
        },
        {
          name: "粮油及调味品",
        },
        {
          name: "休闲食品",
        },
        {
          name: "酒水饮料",
        },
        {
          name: "烘焙食品",
        },
        {
          name: "食品加工机械",
        },
      ],
      data: [
        {
          type: "综合展区",
          title:
            "商品名称限制两行商品名称限制两行商品名称限制两行大约38个字...",
          name: "河南**有限公司食品发展部",
          area: "AA-1003",
          image: require("@/assets/img/displayArea/food.jpg"),
        },
        {
          type: "综合展区",
          title:
            "商品名称限制两行商品名称限制两行商品名称限制两行大约38个字...",
          name: "河南**有限公司食品发展部",
          area: "AA-1003",
          image: require("@/assets/img/displayArea/food.jpg"),
        },
      ],
      productType: "综合展区",
      form: {
        size: 16,
        current: 1,
      },
    };
  },
  methods: {
    addCur() {
      this.form.size = this.form.size + 16;
      this.getData();
    },
    changeTypeIndex(v, i) {
      this.activeIndex = i;
      this.productType = v;
      this.getData();
    },
    getData() {
      getExhibitorsProduct(this.productType, this.form).then((res) => {
        if (res.success) {
          this.data = res.result.records;
        } else {
          this.data = [];
        }
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped lang="less">
.page {
  background-color: #f9f9f9;
  .banner {
    img {
      width: 100%;
    }
  }
  section.city {
    text-align: left;
    margin: 30px 0;
    img {
      width: 100%;
    }
    .title {
      font-size: 22px;
      color: #272727;
      margin-top: 70px;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      color: #272727;
    }
    ul {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      li {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        border-left: 1px solid #eee;
        padding: 0 20px;
        width: calc(100% / 4);
        &:nth-child(1) {
          border: none;
        }
      }
    }
  }
  .section_item2 {
    margin-bottom: 20px;
    div.row {
      background-color: #fff;
    }
    div.item {
      margin-top: 10px;
    }
    p {
      font-size: 14px;
      color: #272727;
      text-align: left;
      text-indent: 2rem;
      padding: 10px;
      line-height: 32px;
    }
  }
  .section_item3 {
    img {
      width: 100%;
    }
  }
  .section_item4 {
    margin: 30px;
    .section_con {
      border-top: 4px solid #e3e3e3;
      .item4_con {
        img {
          width: 100%;
        }
        .image {
          padding: 20px 0;
        }
        .text {
          padding: 0;
          div {
            text-align: left;
            margin: 20px 0;
            padding: 20px;
            background-color: #fff;
            p {
              font-size: 16px;
              color: #272727;
              margin: 0;
              &:nth-child(1) {
                text-indent: 2rem;
                padding-bottom: 10px;
              }
              &:nth-child(2) {
                font-size: 14px;
                text-indent: 2rem;
                line-height: 32px;
              }
            }
          }
        }
        .left {
          border-right: 3px solid #e3e3e3;
        }
      }
      .item4_con_other {
        background-color: #fff;
        padding: 20px;
        border: 1px solid #f3f3f3;
        p {
          font-size: 16px;
          color: #272727;
          // margin: 0;
          &:nth-child(2) {
            font-size: 14px;
            text-indent: 2rem;
            line-height: 32px;
            text-align: left;
          }
        }
      }
    }
  }
}
.list_section {
  .list {
    ul {
      li {
        &.col-lg-3 {
          padding: 0 4px;
        }
        .list_con {
          border: 1px solid #eeeeee;
          &:hover {
            box-shadow: 0px 0px 6px 0px rgba(39, 39, 39, 0.16);
            i {
              color: #3f6ce2;
            }
          }
        }
        img {
          width: 100%;
          padding: 10px;
        }
        p.title {
          font-size: 14px;
          color: #363636;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 10px;
          padding: 4px 10px;
          text-align: left;
        }
        .type_but {
          text-align: left;
          padding: 10px;
          span {
            text-align: center;
            border: 1px solid #eeeeee;
            border-radius: 4px;
            display: inline-block;
            padding: 2px 10px;
            font-size: 14px;
            color: #ff6600;
          }
        }
        .name {
          border-bottom: 1px solid #eee;
        }
        .name,
        .area {
          background-color: #f9f9f9;
          text-align: left;
          padding: 10px;
          i {
            font-size: 14px;
            color: #ababab;
          }
          span {
            font-size: 14px;
            color: #ababab;
            margin-left: 2px;
          }
          p {
            margin-bottom: 0;
            color: #373139;
          }
        }
      }
    }
  }
  .no_more {
    height: 40vh;
    padding-top: 100px;
    i {
      font-size: 100px;
    }
    p {
      margin: 20px;
    }
  }
  .more {
    margin: 10px 0;
    div {
      display: inline-block;
      padding: 6px 30px;
      border: 1px solid #ff6600;
      border-radius: 8px;
      cursor: pointer;
      i {
        color: #ff6600;
      }
      span {
        font-size: 16px;
        color: #ff6600;
      }
    }
  }
}
@media (max-width: 750px) {
  .page {
    .city {
      .item {
        display: none !important;
      }
      ul {
        padding: 20px 0 !important;
        li {
          padding: 0 !important;
        }
      }
    }
    .section_item3 {
      img {
        display: none;
      }
    }
    .section_item4 {
      .item4_con {
        .image {
          display: none;
        }
        .left {
          border: none !important;
        }
      }
    }
  }
}
</style>