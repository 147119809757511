<template>
  <div class="page">
    <section
      class="display-area-banner relative"
      :style="{ backgroundImage: `url(${banner})` }"
    >
      <div class="container">
        <div class="row banner" style="height: 160px">
          <div class="banner-content col-lg-12 col-md-12">
            <h1 class="banner_title">采购商报名</h1>
            <!-- <h1 class="banner_title">观众报名</h1> -->
            <!-- <h5 class="banner_desc">
              本届展会入场采用实名制，请认真填写报名信息。
            </h5> -->
          </div>
        </div>
      </div>
    </section>
    <!-- 观众报名 -->
    <section class="group_section relative" style="display: none">
      <div class="container" style="padding: 0">
        <div class="group">
          <div class="group_title">
            <span>观众报名</span>
          </div>
        </div>
        <div class="form_con">
          <div class="form_title">
            <span>基本信息</span>
            <span style="color: red"
              >基本信息一经提交，将无法修改，请认真填写。</span
            >
          </div>
          <form>
            <div class="form-group row">
              <label class="col-sm-1 col-3 col-form-label">姓名 <i>*</i></label>
              <div class="col-sm-6 col-9">
                <input class="form-control" v-model="form.nickName" required />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-1 col-3 col-form-label"
                >单位名称 <i>*</i></label
              >
              <div class="col-sm-6 col-9">
                <input
                  class="form-control"
                  v-model="form.departmentName"
                  required
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-1 col-3 col-form-label"
                >手机号 <i>*</i></label
              >
              <div class="col-sm-6 col-9 getPhone">
                <input
                  class="form-control"
                  v-model="form.mobile"
                  required
                  @blur="blurPhone"
                /><span
                  class="check_phone"
                  :class="check_phone ? 'active' : ''"
                  >{{ check_msg_phone }}</span
                >
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-1 col-3 col-form-label"
                >验证码 <i>*</i></label
              >
              <div class="col-sm-6 col-9 getCode">
                <input class="form-control" v-model="form.code" required />
                <span v-if="show" @click="getSmsCode">获取验证码</span>
                <span v-else>{{ count }} s后重新获取</span>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-1 col-3 col-form-label"
                >身份证号 <i>*</i></label
              >
              <div class="col-sm-6 col-9">
                <input class="form-control" v-model="form.idCode" required />
              </div>
            </div>
            <div class="but">
              <p class="error_msg">{{ error_msg }}</p>
              <span @click="submit1" class="btn btn-primary">提交</span>
              <!--< button type="submit" class="btn btn-primary" @click="submit">
                提交
              </button> -->
              <div>
                <span
                  >提示：提交即代表您同意注册本网站<br />后续您可以使用填写的手机号码登录本网站。</span
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <!-- 采购商报名 -->
    <section class="group_section relative" >
      <div class="container" style="padding: 0">
        <div class="group">
          <div class="group_title">
            <span>采购商报名</span>
            <span>特别提醒：非专业观众及18周岁以下人士谢绝参观。</span>
          </div>
        </div>
        <div class="form_con">
          <div class="form_title">
            <span>基本信息</span>
            <span style="color: red"
              >基本信息一经提交，将无法修改，请认真填写。</span
            >
          </div>
          <!-- 表单 -->
          <form>
            <div class="form-group row">
              <label class="col-sm-1 col-3 col-form-label">姓名 <i>*</i></label>
              <div class="col-sm-6 col-9">
                <input class="form-control" v-model="form.nickName" required />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-1 col-3 col-form-label"
                >单位名称 <i>*</i></label
              >
              <div class="col-sm-6 col-9">
                <input
                  class="form-control"
                  v-model="form.departmentName"
                  required
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-1 col-3 col-form-label">职位 <i>*</i></label>
              <div class="col-sm-6 col-9">
                <input class="form-control" v-model="form.jobName" required />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-1 col-3 col-form-label"
                >省/市/区 <i>*</i></label
              >
              <div class="col-sm-6 col-9">
                <v-distpicker
                  :placeholders="placeholders"
                  @province="onChangeProvince"
                  @city="onChangeCity"
                  @area="onSelectArea"
                  @selected="onSelected"
                ></v-distpicker>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="exampleInputEmail1"
                class="col-sm-1 col-2 col-form-label"
                >邮箱</label
              >
              <div class="col-sm-6 col-10">
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  v-model="form.email"
                />
                <!-- <small id="emailHelp" class="form-text text-muted"
                  >不能为空</small
                > -->
              </div>
            </div>
            <div
              class="form_title"
              style="border-top: 1px solid #eee; padding: 20px 0"
            >
              <span>采购信息</span>
            </div>
            <div
              class="form-group"
              v-for="(item, index) in info.topicList"
              :key="index"
              v-if="index != 1"
            >
              <div class="pro_title">
                <i style="margin-right: 6px">*</i>{{ item.title }}
              </div>
              <div
                class="form-check"
                v-for="(it, i) in item.selection"
                :key="i"
                @click="chenge(i)"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  v-model="it.check"
                />
                <label class="form-check-label">{{ it.name }}</label>
              </div>
            </div>
            <div
              class="form-group form-check"
              v-for="(item, index) in info.topicList"
              :key="index"
              v-if="index == 1"
            >
              <div class="pro_title">
                <i style="margin-right: 6px">*</i>{{ item.title }}
              </div>
              <div
                class="form-check"
                v-for="(it, i) in item.selection"
                :key="i"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  :value="it.check"
                  @click="chengeRadio(i)"
                />
                <label class="form-check-label" for="inlineRadio1">{{
                  it.name
                }}</label>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-1 col-3 col-form-label"
                >手机号 <i>*</i></label
              >
              <div class="col-sm-6 col-9 getPhone">
                <input
                  class="form-control"
                  v-model="form.mobile"
                  required
                  @blur="blurPhone"
                />
                <span
                  class="check_phone"
                  :class="check_phone ? 'active' : ''"
                  >{{ check_msg_phone }}</span
                >
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-1 col-3 col-form-label"
                >验证码 <i>*</i></label
              >
              <div class="col-sm-6 col-9 getCode">
                <input class="form-control" v-model="form.code" required />
                <span v-if="show" @click="getSmsCode">获取验证码</span>
                <span v-else>{{ count }} s后重新获取</span>
              </div>
            </div>
            <div class="but">
              <p class="error_msg">{{ Msg }}</p>
              <span @click="submit" class="btn btn-primary">提交</span>
              <!--< button type="submit" class="btn btn-primary" @click="submit">
                提交
              </button> -->
              <div>
                <span
                  >提示：提交即代表您同意注册本网站后续您可以使用填写的手机号码登录本网站。</span
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <!--   <div
      class="position-fixed bottom-0 right-0 p-3"
      style="z-index: 5; right: 0; bottom: 0"
    >
      <div
        id="liveToast"
        class="toast"
        :class="clauseMsg ? 'show' : 'hide'"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-delay="2000"
      >
        <div class="toast-header">
           <img src="..." class="rounded mr-2" alt="..." /> 
          <strong class="mr-auto">表单验证失败</strong>
          <small>11 mins ago</small> 
          <button
            type="button"
            class="ml-2 mb-1 close"
            data-dismiss="toast"
            aria-label="Close"
            @click="clauseMsg = false"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body">{{ Msg }}</div>
      </div>
    </div> -->
    <!-- 弹框 -->
    <!--  <button
      type="button"
      class="btn btn-primary"
      data-toggle="modal"
      data-target="#staticBackdrop"
    >
      Launch static backdrop modal
    </button> -->
    <div
      class="modal"
      :class="modal ? 'active' : ' fade'"
      id="staticBackdrop"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel"></h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="modal = false"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div> -->
          <div class="modal-body">
            <img :src="logo" alt="" />
            <h5 class="modal-title" id="staticBackdropLabel">
              2024第二十一届中国（漯河）食品博览会
            </h5>
            <div class="box">
              <div
                class="code"
                :style="{ backgroundImage: `url(${code_back})` }"
              >
                <h4>电子入场券</h4>
                <img :src="code" alt="" />
                <p>
                  请保存您的入场券图片，参观时作为入场凭证使用。<br />
                  您也可以登录后在个人中心重新查看入场券图片。
                </p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="modal = false"
            >
              我已保存
            </button>
            <!-- <button type="button" class="btn btn-primary">Understood</button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show" :class="modal ? 'active' : ''"></div>
  </div>
</template>

<script>
import {
  audienceApply,
  getQuestionnaireById,
  getLoginCode,
  checkMobile,
} from "@/api/index.js";
import axios from "axios";
export default {
  data() {
    return {
      error_msg: "",
      check_msg_phone: "手机号格式不正确",
      check_phone: false,
      count: 0,
      timer: null,
      show: true,
      modal: false, //模态框是否显示
      logo: require("@/assets/img/home/logo.png"),
      code: require("@/assets/img/signUp/code.jpg"),
      code_back: require("@/assets/img/signUp/code_back.jpg"),
      clauseMsg: false, //全局提示信息
      Msg: "",
      placeholders: {
        province: "------- 省 --------",
        city: "--- 市 ---",
        area: "--- 区 ---",
      },
      banner: require("@/assets/img/signUp/banner.jpg"),
      form: {
        email: "",
        mobile: "",
        departmentName: "",
        jobName: "",
        address: "",
        nickName: "",
        userRecord: {},
        code: "",
        questionnaireId: "",
        idCode: "",
      },
      info: [
        /* {
          title: "1、企业所属行业（多选）",
          selection: [
            {
              name: "餐饮与酒店业",
              check: false,
            },
            {
              name: "食品及加工业",
              check: false,
            },
            {
              name: "酒水及咖饮业",
              check: false,
            },
            {
              name: "批发和零售业",
              check: false,
            },
            {
              name: "连锁商超",
              check: false,
            },
            {
              name: "网络服务平台",
              check: false,
            },
            {
              name: "粮油企业",
              check: false,
            },
          ],
        },
        {
          title: "4、您是从哪个渠道了解到本次展会？（单选）",
          selection: [
            {
              name: "户外广告",
              check: false,
            },
            {
              name: "组委会邀请函",
              check: false,
            },
            {
              name: "政府组织、商/协会组织",
              check: false,
            },
            {
              name: "网络",
              check: false,
            },
            {
              name: "其他",
              check: false,
            },
          ],
        },
        {
          title: "2、您参加本次展会的主要目的？（多选）",
          selection: [
            {
              name: "采购/订货",
              check: false,
            },
            {
              name: "投资合作（代理/加盟）",
              check: false,
            },
            {
              name: "政府组织、商/协会组织",
              check: false,
            },
            {
              name: "了解行情",
              check: false,
            },
            {
              name: "其他",
              check: false,
            },
          ],
        },
        {
          title: "3、您的采购需求？（多选）",
          selection: [
            {
              name: "特色食品",
              check: false,
            },
            {
              name: "境外食品",
              check: false,
            },
            {
              name: "肉制品及冻品",
              check: false,
            },
            {
              name: "粮油及调味品",
              check: false,
            },
            {
              name: "休闲食品",
              check: false,
            },
            {
              name: "酒水饮料",
              check: false,
            },
            {
              name: "烘焙食品",
              check: false,
            },
            {
              name: "食品加工机械",
              check: false,
            },
            {
              name: "其他",
              check: false,
            },
          ],
        }, */
      ],
      baseUrl: process.env.VUE_APP_BASE_API,
    };
  },
  methods: {
    blurPhone() {
      var reg = /^[1][3,4,5,6,7,8][0-9]{9}$/;
      if (!this.form.mobile) {
        // 不能为空
        this.check_phone = true;
        this.check_msg_phone = "手机号不能为空";
        this.Msg = "手机号不能为空";
      } else {
        if (!reg.test(this.form.mobile)) {
          // 格式错误
          this.check_phone = true;
          this.check_msg_phone = "手机号格式不正确";
          this.Msg = "手机号格式不正确";
        } else {
          // 后端查重
          this.check_phone = false;
          checkMobile({ mobile: this.form.mobile }).then((r) => {
            if (r.success) {
              this.Msg = "";
            } else {
              this.check_phone = true;
              this.Msg = r.message;
              this.check_msg_phone = r.message;
            }
          });
        }
      }
    },
    // 获取验证码
    getSmsCode() {
      if (this.form.mobile == "") {
        this.clauseMsg = true;
        this.Msg = "手机号不能为空！";
        setTimeout(() => {
          this.clauseMsg = false;
        }, 3000);
      } else {
        if (this.count === 0) {
          getLoginCode(this.form.mobile)
            .then((r) => {
              if (r.success) {
                this.getCode();
              } else {
                this.clauseMsg = true;
                this.Msg = r.message;
                setTimeout(() => {
                  this.clauseMsg = false;
                }, 3000);
              }
            })
            .catch((e) => {
              this.clauseMsg = true;
              this.Msg = e.message;
              setTimeout(() => {
                this.clauseMsg = false;
              }, 3000);
            });
        }
      }
    },
    // 倒计时
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.count = 0;
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    chengeRadio(v) {
      this.info.topicList[1].selection.forEach((item, index) => {
        item.check = false;
        if (v == index) {
          item.check = true;
        }
      });
    },
    // 触发选择省份事件
    onChangeProvince(data) {
      console.log(data);
      // this.select.area = data.value
    },
    // 触发选择城市事件
    onChangeCity(data) {
      console.log(data);
      // this.select.area = data.value
    },
    // 触发选择地区事件
    onSelectArea(data) {
      console.log(data);
      // this.select.area = data.value
    },
    // 选择最后一项时触发事件
    onSelected(data) {
      this.form.address =
        data.province.value + "-" + data.city.value + "-" + data.area.value;
    },
    // 观众报名
    submit1() {
      if (this.form.nickName == "") {
        this.error_msg = "姓名不能为空！";
      } else if (this.form.mobile == "") {
        this.error_msg = "手机号不能为空！";
      } else if (this.form.departmentName == "") {
        this.error_msg = "单位名称不能为空！";
      } else if (this.form.idCode == "") {
        this.error_msg = "身份证号不能为空！";
      } else {
        let cur = {
          nickName: this.form.nickName,
          mobile: this.form.mobile,
          departmentName: this.form.departmentName,
          idCode: this.form.idCode,
        };
        audienceApply(cur).then((r) => {
          if (r.success) {
            this.$router.push("/login");
          } else {
            this.error_msg = r.message;
            console.log(this.error_msg);
          }
        });
      }
    },
    // 采购商报名
    submit() {
      if (!this.form.nickName) {
        this.clauseMsg = true;
        this.Msg = "姓名不能为空！";
        setTimeout(() => {
          this.clauseMsg = false;
        }, 3000);
        return;
      } else if (!this.form.mobile) {
        this.clauseMsg = true;
        this.Msg = "手机号不能为空！";
        setTimeout(() => {
          this.clauseMsg = false;
        }, 3000);
        return;
      } else if (!this.form.departmentName) {
        this.clauseMsg = true;
        this.Msg = "单位名称不能为空！";
        setTimeout(() => {
          this.clauseMsg = false;
        }, 3000);
        return;
      } else if (!this.form.jobName) {
        this.clauseMsg = true;
        this.Msg = "职位称不能为空！";
        setTimeout(() => {
          this.clauseMsg = false;
        }, 3000);
        return;
      } else if (!this.form.address) {
        this.clauseMsg = true;
        this.Msg = "地址不能为空！";
        setTimeout(() => {
          this.clauseMsg = false;
        }, 3000);
        return;
      } else if (!this.form.code) {
        this.clauseMsg = true;
        this.Msg = "验证码不能为空！";
        setTimeout(() => {
          this.clauseMsg = false;
        }, 3000);
        return;
      } else {
        // 验证调查问卷
        let flage = false;
        let arr = [];
        this.info.topicList.forEach((item, i) => {
          arr.push(0);
          item.selection.forEach((v) => {
            if (v.check) {
              arr[i]++;
            }
          });
        });
        /*  arr.forEach((item, i) => {
          if (item != 0) {
            flage = true;
          } else {
            console.log("表单验证失败！！！");
            this.clauseMsg = true;
            this.Msg = "您的信息没有填写完整！";
            flage = false;
            setTimeout(() => {
              this.clauseMsg = false;
            }, 3000);
            return;
          }
        }); */
        if (arr[0] != 0 && arr[1] != 0 && arr[2] != 0 && arr[3] != 0) {
          flage = true;
        } else {
          flage = false;
          this.clauseMsg = true;
          this.Msg = "您的信息没有填写完整！您漏掉了一个选项哟~";
          flage = false;
          setTimeout(() => {
            this.clauseMsg = false;
          }, 3000);
          return;
        }
        if (flage) {
          // this.this.info
          let cur = this.form;
          cur.userRecord = this.info;
          cur.userRecord.questionnaireId = this.form.questionnaireId;
          cur.userRecord.topicList = JSON.stringify(
            this.form.userRecord.topicList
          );
          cur = JSON.stringify(cur);
          axios({
            url: this.baseUrl + "/exhibitors/buyersApply",
            method: "post",
            data: cur,
            headers: {
              "Content-Type": "application/json;",
            },
          }).then((r) => {
            if (r.success) {
              this.modal = true;
              this.Msg = "";
              this.$router.push("/login");
            } else {
              this.clauseMsg = true;
              this.Msg = r.message;
              flage = false;
              setTimeout(() => {
                this.clauseMsg = false;
              }, 3000);
            }
          });
        }
      }
    },
    chenge(v) {
      // console.log(v);
    },
    // 调查问卷数据
    getQuestData() {
      getQuestionnaireById(1).then((res) => {
        if (res.success) {
          this.info = JSON.parse(res.result.content);
          this.form.questionnaireId = res.result.id;
        } else {
          this.info = {};
        }
      });
    },
  },
  created() {
    this.getQuestData();
  },
};
</script>

<style scoped lang="less">
.display-area-banner {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  padding: 20px 0;
  div.banner {
    .banner-content {
      text-align: left;
      margin: 0;
    }
    .banner_title {
      //   font-size: 37px;
      color: #ffffff;
      margin-top: 25px;
    }
    .banner_desc {
      //   font-size: 16px;
      color: #ffffff;
      font-weight: 400;
      padding: 10px 20px;
      background-color: rgba(148, 109, 18, 0.5);
      border-radius: 10px;
    }
  }
}
.group_section {
  margin: 20px 0;
  .group {
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    margin: 20px 0px 0 0px;
    .group_title {
      text-align: left;
      font-size: 18px;
      color: #3b3b3b;
      background-color: #f9f9f9;
      padding: 10px 20px;
      span {
        &:nth-child(2) {
          font-size: 14px;
          color: #b5b5b5;
          margin-left: 6px;
        }
      }
    }
  }
}
.form_con {
  border: 1px solid #eeeeee;
  padding: 20px;
  text-align: left;
  .form_title {
    text-align: left;
    font-size: 16px;
    color: #272727;
    margin: 20px 0;
    span {
      &:nth-child(2) {
        color: silver;
        font-size: 14px;
        margin-left: 6px;
      }
    }
  }
  .col-form-label {
    text-align: right;
  }
  .form-group {
    i {
      color: red;
    }
    label {
      font-size: 14px;
      color: #707070;
      padding: 10px;
    }
    .invalid-feedbac {
      display: none;
      color: red;
    }
    .form-check {
      margin: 0 20px;
    }
    .form-check-input {
      margin-top: 1rem;
    }
    .pro_title {
      font-size: 14px;
      color: #707070;
    }
  }
}
.but {
  text-align: center;
  p.error_msg {
    color: red;
    &.active {
      display: block;
    }
  }
  div {
    padding: 20px;
    font-size: 14px;
    color: #b5b5b5;
    p {
      margin: 0;
    }
  }
  .btn-primary {
    background-color: #ff6600;
    border-color: #ff6600;
    padding-left: 50px;
    padding-right: 50px;
  }
}
.toast-body {
  color: red;
}
.modal {
  //   top: 10vh;
  &.active {
    display: block;
  }
}
.modal-backdrop {
  display: none;
  &.active {
    display: block;
  }
}
.box {
  padding: 30px 10px;
  h4 {
    color: #fff;
    padding: 0px 0 20px 0;
  }
  .code {
    background-size: cover;
    padding: 20px 0px;
    img {
      width: 100%;
      padding: 0 50px;
    }
    p {
      color: #fff;
      font-size: 14px;
      margin: 10px 0 0 0;
    }
  }
}
.btn-secondary {
  background-color: #ff6600;
  border-color: #ff6600;
}
.getCode {
  input {
    display: inline-block;
    color: #175199;
    width: 60%;
  }
  span {
    padding: 10px 20px;
    display: inline-block;
    color: #175199;
    font-size: 14px;
    cursor: pointer;
  }
}
.check_phone {
  display: none;
  font-size: 14px;
  color: red;
  &.active {
    display: block;
  }
}
@media (max-width: 750px) {
  .getCode {
    span {
      padding: 10px 10px;
    }
  }
  .banner_desc {
    font-size: 14px;
    line-height: 22px !important;
  }
  .display-area-banner div.banner .banner_title {
    margin-top: 20px;
  }
}
</style>