<template>
  <div class="page">
    <div class="page_con" :style="{ backgroundImage: `url(${login_back})` }">
      <div class="container">
        <div class="login_item">
          <img :src="logo" alt="" @click="openWeb('/index')" />
          <div class="title">
            <span>手机号快捷登录</span>
          </div>
          <form>
            <div class="form-group row">
              <div class="col-12 getPhone">
                <input
                  class="form-control"
                  v-model="form.mobile"
                  required
                  @blur="blurPhone"
                  placeholder="手机号"
                />
                <span class="checkPhone" :class="check_phone ? 'active' : ''">{{
                  check_msg_phone
                }}</span>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-8">
                <input
                  class="form-control"
                  v-model="form.code"
                  required
                  placeholder="输入6位短信验证码"
                  @blur="blurCode"
                />
              </div>
              <div class="col-4" style="margin-top: 15px; cursor: pointer">
                <span v-if="show" @click="getSmsCode" style="color: #175199;font-size:14px"
                  >获取验证码</span
                >
                <span v-else style="color: #175199;font-size:14px"
                  >{{ count }} s后重新获取</span
                >
              </div>
              <span class="checkCode" :class="check_code ? 'active' : ''">{{
                check_msg_code
              }}</span>
            </div>
            <div class="but">
              <p class="error_msg">{{ errorMsg }}</p>
              <span @click="submit" class="btn btn-primary">登录</span>
              <!--< button type="submit" class="btn btn-primary" @click="submit">
                提交
              </button> -->
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLoginCode, checkMobile } from "@/api/index.js";
import { mapState } from "vuex";
export default {
  data() {
    return {
      errorMsg: "",
      login_back: require("@/assets/img/login_back.jpg"),
      logo: require("@/assets/img/logo.png"),
      form: {
        mobile: "",
        code: "",
      },
      check_msg_code: "验证码不能为空",
      check_code: false,
      check_msg_phone: "手机号格式不正确",
      check_phone: false,
      count: 0,
      timer: null,
      show: true,
      flag: false, //手机号和code都不为空
    };
  },
  methods: {
    openWeb(v) {
      this.$router.push(v);
    },
    blurCode() {
      if (!this.form.code) {
        // 不能为空
        this.check_code = true;
        this.check_msg_code = "验证码不能为空";
        this.flag = false;
      } else {
        this.check_code = false;
        this.flag = true;
      }
    },
    blurPhone() {
      var reg = /^[1][3,4,5,6,7,8][0-9]{9}$/;
      if (!this.form.mobile) {
        // 不能为空
        this.check_phone = true;
        this.check_msg_phone = "手机号不能为空";
        this.flag = false;
      } else {
        if (!reg.test(this.form.mobile)) {
          // 格式错误
          this.check_phone = true;
          this.check_msg_phone = "手机号格式不正确";
          this.flag = false;
        } else {
          this.flag = true;
          this.check_phone = false;
          /* // 后端查重
          this.check_phone = false;
          checkMobile({ mobile: this.form.mobile }).then((r) => {
            if (r.success) {
              this.flag = true;
            } else {
              this.check_phone = true;
              this.check_msg_phone = r.message;
              this.flag = false;
            }
          }); */
        }
      }
    },
    submit() {
      if (this.flag) {
        this.errorMsg = "";
        // 登录
        this.$store
          .dispatch("login", this.form)
          .then((r) => {
            console.log(r);
            // console.log(666);
            if (r == "success") {
              this.$router.push("/center");
            } else {
              this.errorMsg = r;
            }
            // 登录成功后--获取企业信息
            /* this.$store
              .dispatch("getEnterData", this.userInfo.enterpriseId)
              .then((res) => {
                // console.log(res);
                this.$router.push("/index");
              })
              .catch((err) => {
                this.$message({
                  message: err.message,
                  type: "warning",
                });
              }); */
          })
          .catch((e) => {
            // console.log(e);
            this.errorMsg = e;
            // console.log(this.errorMsg);
          });
      } else {
        this.errorMsg = "您的信息尚未填写完整";
      }
    },
    // 获取验证码
    getSmsCode() {
      if (this.form.mobile == "") {
        this.check_phone = true;
        this.check_msg_phone = "手机号不能为空";
      } else {
        if (this.count === 0) {
          getLoginCode(this.form.mobile)
            .then((r) => {
              if (r.success) {
                this.getCode();
              } else {
                this.check_code = true;
                this.check_msg_code = r.message;
              }
            })
            .catch((e) => {
              this.check_code = true;
              this.check_msg_code = r.message;
            });
        }
      }
    },
    // 倒计时
    getCode() {
      const TIME_COUNT = 30;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.count = 0;
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  .page_con {
    height: 80vh;
    // width: 100vw;
    background-size: cover;
    text-align: center;
    padding-top: 150px;
    .login_item {
      width: 400px;
      background-color: #fff;
      padding: 50px 30px;
      margin: 0 auto;
      img {
        width: 100%;
        cursor: pointer;
      }
      .title {
        text-align: left;
        margin-top: 20px;
        span {
          font-size: 16px;
          color: #212121;
          display: inline-block;
          border-bottom: 3px solid #ff6600;
          padding: 10px 0;
          cursor: pointer;
        }
      }
      input {
        width: 100%;
        &.form-control {
          border: none;
          outline: none;
          border-bottom: 1px solid #eee;
          margin-top: 15px;
        }
        &.form-control:focus {
          outline: none;
          border: none;
          border-bottom: 1px solid #80bdff;
          box-shadow: none;
        }
      }
      .btn-primary {
        background: #ff6600;
        border-color: #ff6600;
        width: 80%;
        margin-top: 10px;
      }
    }
  }
  .checkPhone,
  .checkCode {
    color: red;
    margin-top: 2px;
    margin-left: 10px;
    width: 100%;
    text-align: left;
    display: inline-block;
    font-size: 14px;
    display: none;
    &.active {
      display: inline-block;
    }
  }
  .checkCode {
    margin-left: 25px;
  }
  .form-group{
    .col-4{
      padding: 0;
    }
  }
}
.error_msg {
  color: red;
  font-size: 14px;
  margin: 0;
}
@media (max-width: 750px) {
  .page {
    .page_con {
      padding-top: 50px;
    }
    .login_item {
      width: 100% !important;
    }
  }
}
</style>