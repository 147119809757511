<template>
  <div class="page">
    <div class="container">
      <div class="page_con">
        <div class="page_title">2024年第21届中国（漯河）食品博览会组织机构</div>
        <div class="page_item_con">
          <p>活动名称：第二十一届中国（漯河）食品博览会。</p>
          <p>举办时间：2024年5月16日—18日。</p>
          <p>
            主办单位：中国食品工业协会、中国商业联合会、中国食品和包装机械工业协会、中国食品土畜进出口商会
          </p>
          <p>承办单位：河南省食品工业协会、河南省博览事务局有限公司</p>
          <p>支持单位：中原食品实验室、英国河南商会、马来西亚—中国商务理事会、马来西亚豫商联合会、泰中文化经济协会、白俄罗斯河南商会、香港食品安全交流协会</p>
          <p>执行承办单位：河南省博览事务局有限公司</p>
          <p>举办地点：漯河市国际会展中心、中原食品实验室、漯河各旅游景区</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.page {
  background: #f9f9f9;
  padding: 30px 0;
  .page_con {
    background: #fff;
    padding: 30px 20px;
    .page_title {
      font-size: 18px;
      color: #595959;
      padding: 20px 0;
      border-bottom: 1px solid #eee;
    }
    .page_item_con {
      padding: 30px 10px;
      font-size: 14px;
      color: #595959;
      text-align: left;
      text-indent: 2rem;
      line-height: 28px;
      // min-height: 50vh;
    }
  }
}
</style>