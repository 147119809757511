
// import http from '~/plugins/axios';
import { getStore, setStore } from '@/libs/storage'
import { authLogin } from "@/api/index.js";
const state = {
  user: {
    accessToken: "",
    token: "",
    userType: -1
  },
  isLogin: false
};
const getters = {
  isLogin: state => {
    if (typeof state.user != 'undefined' && state.user.accessToken) {
      return true;
    } else {
      return false;
    }
  },
  user: state => state.user
};
const actions = {
  // 退出登录
  logout({
    state,
    getters,
    commit
  }) {
    document.cookie = "";
    commit('set_user', '');
    commit('set_enter', '');
    commit('set_token', '');
    console.log('logout ok!');
  },
  // 登录
  login({
    state,
    getters,
    commit
  }, formData) {
    return new Promise((resolve, reject) => {
      authLogin(formData).then(res => {
        if (res.success) {
          commit('set_token', res.result.accessToken);
          commit('set_user', res.result);
        }
        resolve(res.message);
      }).catch(err => {
        reject(err);
      });
    })
  },
  // 注册
  // register({
  //   state,
  //   getters,
  //   commit
  // }, payload) {
  //   return new Promise((resolve, reject) => {
  //     http.post("/auth/register", payload).then(res => {
  //       commit('set_user', res);
  //       resolve(res);
  //     }).catch(err => {
  //       reject(err);
  //     });
  //   });
  // },
  // 更新token
  // refreshToken({
  //   state,
  //   getters,
  //   commit
  // }, payload) {
  //   return new Promise((resolve, reject) => {
  //     http.get("/auth/token/refresh").then(res => {
  //       if (process.browser) {
  //         sessionStorage.jwtToken = res.jwtToken;
  //         localStorage.jwtToken = res.jwtToken;
  //       }
  //       commit('set_token', res.jwtToken);
  //       resolve(res);
  //     }).catch(err => {
  //       reject(err);
  //     });
  //   });
  // },
  reloadUserInfo({
    state,
    getters,
    commit
  }, payload) {

  }
};
const mutations = {
  set_token: (state, payload) => {
    // console.log(payload)//token
    // console.dir(state)
    if (payload) {
      state.user.token = payload;//更新token
      state.user.accessToken = payload;//更新user的token
      setStore('accessToken', payload);
    } else {
      setStore('accessToken', payload);
    }
  },
  set_user: (state, payload) => {
    state.user = payload;
    if (process.browser) {
      setStore('user', payload)
    }
  },
  getStoreData: (state, payload) => {
    if (process.browser && getStore('user')) {
      state.user = JSON.parse(getStore('user'))
      state.enterData = JSON.parse(getStore('enterData'))
      state.user.token = getStore('accessToken')
      state.user.accessToken = getStore('accessToken')
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};

