<template>
  <div class="page">
    <div class="container">
      <div class="page_con">
        <div class="page_title">{{ data.title }}</div>
        <div class="page_item_con" v-html="data.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInfoById } from "@/api/index.js";
export default {
  data() {
    return {
      data: {},
    };
  },
  methods: {
    getData(v) {
      getInfoById(v).then((res) => {
        if (res.success) {
          this.data = res.result;
        } else {
          this.data = {};
        }
      });
    },
  },
  watch: {
    $route: {
      handler() {
        this.getData(this.$route.query.id); // this.getExhibtorData(this.$route.query.id); // this.getProductData(this.$route.query.id);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="less">
.page {
  background: #f9f9f9;
  padding: 30px 0;
  .page_con {
    background: #fff;
    padding: 30px 20px;
    .page_title {
      font-size: 18px;
      color: #595959;
      padding: 20px 0;
      border-bottom: 1px solid #eee;
    }
    .page_item_con {
      padding: 30px 10px;
      font-size: 14px;
      color: #595959;
      text-align: left;
      line-height: 28px;
      overflow: hidden;
    }
  }
}
@media (max-width: 750px) {
  .page_item_con {
    /deep/ img {
      width: 100%!important;
    }
  }
}
</style>