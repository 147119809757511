<template>
  <div class="m_nav_page">
    <div class="page_con">
      <ul>
        <li
          v-for="(item, index) in navList"
          :key="index"
          :class="$route.path == item.url ? 'active' : ''"
          @click="openWeb(item.url)"
        >
          <div>
            <i class="iconfont" :class="item.ico"></i>
            <p>{{ item.title }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          title: "首页",
          ico: "iconshouye1",
          url: "/index",
        },
        {
          title: "主题展区",
          ico: "iconlifangtilitiduomiantifangkuai2",
          url: "/displayArea",
        },
        {
          title: "新闻中心",
          ico: "iconxinwenzixun",
          url: "/news",
        },
        {
          title: "联系我们",
          ico: "icondianhualianxi",
          //   url: "/contactUs",
        },
      ],
      phoneNumber:"15903649003"
    };
  },
  methods: {
    openWeb(v) {
      if (v) {
        this.$router.push(v);
      } else {
        window.location.href = "tel:" + this.phoneNumber;
      }
    }, 
    // 调用拨号功能
    callPhone(phoneNumber) {
      window.location.href = "tel://" + phoneNumber;
    },
  },
};
</script>

<style scoped lang="less">
.m_nav_page {
  .page_con {
    ul {
      display: flex;
      justify-content: space-around;
      background-color: #f0f0f0;
      padding: 6px 0 10px;
      border-top: 1px solid #eee;
      li {
        color: #8c8c8c;
        &.active {
          i {
            color: #e66d12;
          }
          p {
            color: #e66d12;
          }
        }
        i {
          font-size: 24px;
          margin-top: 4px;
        }
        p {
          font-size: 14px;
          margin: 0;
        }
      }
    }
  }
}
</style>