<template>
  <div class="page">
    <section class="banner" :style="{ backgroundImage: `url(${banner})` }">
      <div class="container">
        <div class="ban_con">
          <div class="ex_title">
            <div class="title">
              <h3>{{ exData.name }}</h3>
            </div>
            <div class="logo" v-if="exData.logo">
              <img :src="exData.logo" alt="" />
            </div>
          </div>
          <div class="ex_con">
            <p>展区类别：{{ exData.exhibitorsArea }}</p>
            <p>展区展位：{{ exData.exhibitorsBooth }}</p>
            <p>展品：{{ exData.appleExhibit }}</p>
            <p>联系人：{{ exData.contact }}</p>
            <p>联系电话：{{ exData.contactPhone }}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="exhibitor_desc">
      <div class="container">
        <div class="title">
          <span>公司简介</span>
        </div>
        <p>{{ exData.introduction }}</p>
      </div>
    </section>
    <section class="product">
      <div class="container">
        <div class="title">
          <span>展品展示</span>
        </div>
        <ul class="pro_list row">
          <li
            class="col-lg-3 col-6"
            v-for="(item, index) in proData"
            :key="index"
          >
            <div class="list_con" @click="openWeb('/proDetail?id=' + item.id)">
              <div :style="{ backgroundImage: `url(${item.path[0]})` }">
                <!-- 
              <img :src="item.path" alt="" /> -->
              </div>
              <p class="title">
                {{ item.name }}
              </p>
            </div>
          </li>
        </ul>
        <div
          class="more"
          @click="addCur"
          v-if="total > 12 && total != data.length"
        >
          <div>
            <i class="iconfont iconjiazai"></i>
            <span>加载更多</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  getExhibitorsById,
  getProductByExhibitorsId,
  statisticsData,
} from "@/api/index.js";
export default {
  data() {
    return {
      exData: {},
      proData: [],
      banner: require("@/assets/img/exhibitor/detail-banner.jpg"),
      size: 12,
      total: "",
    };
  },
  methods: {
    openWeb(v) {
      this.$router.push(v);
    },
    addCur() {
      this.size = this.size + 12;
      this.getProductData(this.$route.query.id);
    },
    getProductData(id) {
      getProductByExhibitorsId({ exhibitorsId: id, size: this.size }).then(
        (res) => {
          if (res.success) {
            res.result.records.forEach((item) => {
              item.path = JSON.parse(item.path);
            });
            this.proData = res.result.records;
            this.total = res.result.total;
          } else {
            this.proData = [];
          }
        }
      );
    },
    getExhibtorData(id) {
      getExhibitorsById(id).then((res) => {
        if (res.success) {
          this.exData = res.result;
        } else {
          this.exData = {};
        }
      });
    },
    getstatistics() {
      statisticsData().then((res) => {});
    },
  },
  created() {},
  watch: {
    $route: {
      handler() {
        this.getExhibtorData(this.$route.query.id);
        this.getProductData(this.$route.query.id);
        this.getstatistics();
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="less">
.page {
  background-color: #f9f9f9;
  padding-bottom: 30px;
  .title {
    margin: 30px 0;
    font-size: 20px;
    color: #212121;
    text-align: left;
    span {
      border-bottom: 4px solid #ff6600;
      display: inline-block;
      padding: 10px 0;
    }
  }
  .banner {
    padding-top: 120px;
    background-size: cover;
    background-repeat: no-repeat;
    .ban_con {
      background: #ffffff;
      box-shadow: 0px 0px 8px 0px rgba(39, 39, 39, 0.29);
      border-radius: 10px;
      padding: 30px 50px;
      .ex_title {
        .title {
          width: 50%;
          display: inline-block;
          vertical-align: middle;
          text-align: left;
        }
        .logo {
          width: 50%;
          display: inline-block;
          vertical-align: middle;
          text-align: right;
          max-height: 200px;
          overflow: hidden;
          img {
            max-width: 200px;
          }
        }
      }
      .ex_con {
        text-align: left;
        font-size: 16px;
        color: #707070;
      }
    }
  }
  .exhibitor_desc {
    p {
      font-size: 16px;
      color: #212121;
      line-height: 32px;
      text-align: left;
      text-indent: 2rem;
    }
  }
  .pro_list {
    background-color: #fff;
    margin-bottom: 20px;
    li {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .list_con {
      cursor: pointer;
      div {
        min-height: 200px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid #eee;
      }
      img {
        width: 100%;
      }
      p {
        font-size: 14px;
        color: #212121;
        margin: 10px 10px 0 10px;
        // min-height: 40px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: center;
      }
    }
  }
  .more {
    margin: 10px 0;
    div {
      display: inline-block;
      padding: 6px 30px;
      border: 1px solid #ff6600;
      border-radius: 8px;
      cursor: pointer;
      i {
        color: #ff6600;
      }
      span {
        font-size: 16px;
        color: #ff6600;
      }
    }
  }
}
@media (max-width: 750px) {
  .ex_title {
    .logo {
      display: none !important;
    }
    .title {
      width: 100% !important;
    }
  }
  .page .title {
    text-align: center;
  }
}
</style>