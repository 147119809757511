import Vue from 'vue'
import Vuex from 'vuex'

// 引入vuex模块
import user_module from './modules/user.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    keyword: ""
  },
  actions: {
  },
  mutations: {
  },
  modules: {
    user_module,
  }
})





